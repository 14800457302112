import { SharedUtils } from "../utils/utils";
import { API } from "./api";

export class FileAPI extends API {

  static getPatientsRemovedWidget(professionalId: any): Promise<any> {
    const url = `${this.getBaseUrl()}/file/temp/${professionalId}_PATIENT_CONTACT`;
    return this.get(url, {sortKey: "PATIENT_CONTACT"});
  }

  static uploadAvatar(ownerId: string, image: any): Promise<any> {
    const url = `${this.getBaseUrl()}/file/avatar/upload`;
    const data = {
      ownerId: ownerId
    }
    return this.post(url, data).then((result: any)=>{
      return this.put(result, image, {});
    });
  }

  static uploadLogo(ownerId: string, image: any): Promise<any> {
    const url = `${this.getBaseUrl()}/file/logo/upload`;
    const data = {
      ownerId: ownerId
    }
    return this.post(url, data).then((result: any)=>{
      return this.put(result, image, {});
    });
  }

  static getPatientFiles(patientId: string): Promise<any> {
    const url = `${this.getBaseUrl()}/file/patient/${patientId}/list`;
    return this.get(url, {});
  }

  static uploadFile(patientId: string, file: any, fileName: any, folderName: any = null, folderType: any = null): Promise<any> {
    const url = `${this.getBaseUrl()}/file/patient/${patientId}/file/upload`;
    let data: any = {
      fileName: fileName
    }

    if (folderName) data.folderName = folderName;
    if (folderType) data.folderType = folderType;

    return this.post(url, data).then((result: any)=>{
      return this.put(result.presignedUrlPost, file, {}).then(()=>{
        return result.fileKey;
      });
    });
  }

  static downloadFile(patientId: string, filePath: string): Promise<any> {
    const url = `${this.getBaseUrl()}/file/patient/${patientId}/file/download`;
    const data = {
      filePath: filePath
    }

    return this.post(url, data).then((result: any)=>{
      window.location.href = result;
    });
  }

  static updateRecordThumbnail(itemType: string, itemId: string, qrCode: boolean = false, version: string | null = null): Promise<any> {
    let url = `${this.getBaseUrl()}/file/${itemType}/${itemId}/thumbnail/upload`;
    let queryParams = { qrCode: qrCode, version: version};

    return this.get(url, queryParams);
  }

  static updateRecordVideo(recordId: string, protocolId: string, qrCode: boolean = false, prepVideo: boolean = false, version: string | null = null): Promise<any> {
    let url = `${this.getBaseUrl()}/file/record/${recordId}/update/videoKey`;
    let queryParams = { qrCode: qrCode, prepVideo: prepVideo};

    let data: any = {
      protocolId: protocolId
    };

    if (version) data.version = version;

    return this.post(SharedUtils.addQueryParamsToURL(url, queryParams), data, 20000);
  }

  static putFile(url: string, data: any, headers: any = {}): Promise<any> {
    return this.put(url, data, headers);
  }

  static getTemp(tempId: string): Promise<any> {
    const url = `${this.getBaseUrl()}/file/temp/${tempId}?sortKey=QR`;
    return this.getWithoutAuth(url, {});
  }

  static getBackendVersion(): Promise<any> {
    const url = `${this.getBaseUrl()}/file/project/version`;
    return this.get(url, {});
  }
}