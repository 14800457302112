import { Component, Input, OnInit, Output, EventEmitter, TemplateRef, ContentChild  } from '@angular/core';

@Component({
  selector: 'rehub-bar-selector',
  templateUrl: './bar-selector.component.html',
  styleUrls: ['./bar-selector.component.scss'],
})
export class BarSelectorComponent implements OnInit {

  @Input() isSearch: boolean;
  @Input() items: any;
  @Output() itemSelected = new EventEmitter();

  @ContentChild(TemplateRef) templateRef : TemplateRef<any>;

  constructor() {}

  ngOnInit() {}

  selectItem(item: any) {
    this.itemSelected.emit(item);
  }

}
