import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'rehub-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit {

  @Input() image: string;
  @Input() size:number = 50;
  @Input() showBorder: boolean = false;
  @Input("name") set setName(name: string){
    if(name == null) return;
    if(name.trim() == "") this.name = "-"
    else this.name = name;
  }
  @Output() onClick = new EventEmitter();

  name: string;
  customStyle: any = {};

  constructor() {}

  ngOnInit() {
    if (this.showBorder) this.customStyle = {
      border: "5px dashed var(--color-primary)"
    };
  }

  click(){
    this.onClick.emit();
  }
}