import { Component, OnInit, Input } from '@angular/core';
import { CalendarUtils } from '@app/@shared/calendar/calendar-utils';

@Component({
  selector: 'rehub-calendar-during',
  templateUrl: './calendar-during.component.html',
  styleUrls: ['./calendar-during.component.scss'],
  host: { class: 'rounded border bg-white d-block' },
})
export class CalendarDuringComponent implements OnInit {

  CalendarUtils = CalendarUtils;

  duringDayNames = CalendarUtils.getDuringDayNames();
  dayNames = CalendarUtils.getDaysNames();
  values: any = [];

  @Input() during: any;

  constructor(
  ) {}

  ngOnInit() {
    this.dayNames.forEach((dayName: any)=>{
      this.values.push(this.getDayValue(dayName));
    });
    console.log(this.values);
  }

  getDayValue(dayName: any){
    let index = this.dayNames.indexOf(dayName);
    let duringName = this.duringDayNames[index];
    let value = this.during[duringName];
    return value ? parseInt(this.during[duringName]) : 0;
  }


}
