const ACCENTS: {[accent: string]: string} = {
  a: 'àáâãäåæ',
  c: 'ç',
  e: 'èéêëæ',
  i: 'ìíîï',
  n: 'ñ',
  o: 'òóôõöø',
  s: 'ß',
  u: 'ùúûü',
  y: 'ÿ'
};

export class SharedUtils {

  //TODO: mover
  public static REASON_GROUP_LOGIN: number = 1;
  public static REASON_GROUP_REALTIME: number = 2;
  public static REASON_REALTIME_NO_RESET: string = "realtime_1";
  public static REASON_REALTIME_NO_INSTALL: string = "realtime_2";

  /**
   * @returns Devuelve el sistema operativo actual
   */
  public static getOS(){
    let ua = navigator.userAgent.toLowerCase();

    if(ua.includes("windows")) return "Windows";
    else if(ua.includes("crkey") || ua.includes("tv")) return "Chromecast";
    else if(ua.includes("x11")) return "Linux";
    else if(ua.includes("android") || ua.includes("linux")) return "Android";
    else if(["ios", "iphone", "ipad"].some((i)=>ua.includes(i))) return "iOS";
    else if(ua.includes("mac")) return "Mac";
    else return "Unknown";
  }

  /**
   * @param stage Nombre del stage que se quiere comprobar
   * @param stages Lista de stages contra los que se quiere comprobar
   * @returns Devuelve si el stage se encuentra en la lista de stages
   */
  // TODO: mover a stage service?
  public static isStage(stage: string, stages: string[]){
    return stages.some((s: string)=>{
      return stage.includes(s);
    });
  }

  /**
   * @param stage Nombre del stage que se quiere comprobar
   * @param stages Lista de stages contra los que se quiere comprobar
   * @returns Devuelve si el stage se encuentra en la lista de stages
   */
  // TODO: mover a stage service?
  public static isStageDevelopmentControl(stage: string, stages: string[]){
    return stages.some((s: string)=>{
      return stage === s;
    });
  }

  /**
   * @returns Devuelve si el sistema operativo actual es Windows
   */
  public static isWindows(){
    return this.getOS() == "Windows";
  }

  /**
   * @returns Devuelve si el sistema operativo actual es Chromecast
   */
  public static isChromecast() {
    return this.getOS() == "Chromecast";
  }

  /**
   * @returns Devuelve si el sistema operativo actual es Linux
   */
  public static isLinux(){
    return this.getOS() == "Linux";
  }

  /**
   * @returns Devuelve si el sistema operativo actual es Mac
   */
  public static isMac(){
    return this.getOS() == "Mac";
  }

  /**
   * @returns Devuelve si el sistema operativo actual es iOS o mac
   */
  public static isIOS(){
    return this.getOS() == "iOS" || this.isMac();
  }
  /**
   * @returns Devuelve si el sistema operativo actual es iOS
   */
  public static isOnlyIOS(){
    return this.getOS() == "iOS";
  }

  /**
   * @returns Devuelve si el sistema operativo actual es Android
   */
  public static isAndroid(){
    return this.getOS() == "Android";
  }

  /**
   * @param number Número a formatear
   * @param length Longitud total del string
   * @returns Devuelve el número con los ceros a la izquierda necesarios para que tenga la longitud indicada
   */
  public static appendZerosToNumber(number: number, length: number = 3){
    return String(number).padStart(length, "0");
  }

  /**
   * @returns Devuelve si la pantalla es vertical
   */
  public static isPortrait(){
    return SharedUtils.getScreenHeight() > SharedUtils.getScreenWidth();
  }

  /**
   *
   * @returns Devuelve si la pantalla es horizontal
   */
  public static isLandscape(){
    return SharedUtils.getScreenWidth() > SharedUtils.getScreenHeight();
  }

  /**
   * @returns Devuelve el ancho de la pantalla
   */
  public static getScreenWidth(){
    return window.innerWidth; //Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  }

  /**
   * @returns Devuelve el alto de la pantalla
   */
  public static getScreenHeight(){
    return  window.innerHeight; //Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
  }

  /**
  * @param quality Valor de la calidad
  * @returns Devuelve el color de la calidad según el valor de la calidad
  */

  public static getQualityColor(quality: number) {
    if (quality == -1) return "black";
    else if (quality == null) return "gray-light";
    else if (quality <= 33 || quality >= 166) return "red";
    else if (quality < 66 || quality > 133) return "yellow";
    else if (quality <= 133) return "green";
  }

  /**
   * @returns Devuelve si la pantalla es inferior o igual a 600px de ancho
   */
  public static isLittleScreenMobile() {
    return SharedUtils.getScreenWidth() <= 600;
  }

  /**
   * @returns Devuelve si la pantalla es inferior o igual a 1280px de ancho
   */
  public static isScreenWidthLittle() {
    return SharedUtils.getScreenWidth() <= 1280;
  }

  /**
   * @returns Devuelve si la pantalla es vertical o inferior o igual a 992px de ancho
   */
  public static isMobile(){
    return SharedUtils.isPortrait() || SharedUtils.getScreenWidth() <= 992;
  }

  // TODO: mover a user utils?
  public static isIdUser(id: string): boolean {
    return this.isIdByTypeUser(id, "u");
  }

  // TODO: mover a user utils?
  public static isIdPatient(id:string): boolean {
    return this.isIdByTypeUser(id, "p");
  }

  // TODO: mover a user utils?
  public static isIdByTypeUser(id: string, typeUser: "p" | "u"): boolean {
    return id.includes(typeUser + "_") || id.includes("_" + typeUser);
  }

  //TODO: mover a protocol-utils
  public static getToolsFormatted(tools: string[], environment: any){
    return tools.map((tool: any) => {
      return {
        url: environment.professional_url + "/assets/images/tools/" + tool + ".png",
        name: tool
      };
    });
  }

  //TODO: mover a protocol-utils
  public static getSensorTypeIcon(sensorType: string): string {
    let icons: any = {
      MAIN: "sensor_main",
      HANDLE: "sensor_handle",
      PRESSURE: "sensor_pressure",
      CAMERA: "sensor_camera",
      NONE: "sensor_manual"
    }

    return icons[sensorType];
  }

  //TODO: mover a protocol-utils
  public static getToolIcon(tool: string): string{
    let icons: any = {
      strength: "tool_strength",
      stick: "tool_stick",
      ball: "tool_ball",
      weight: "tool_weight",
      chair: "tool_chair",
      roll: "tool_roll",
      towel: "tool_towel",
      table: "tool_table",
      ribbon_rope: "tool_ribbon_rope",
      little_ball: "tool_little_ball",
      yoga_block: "tool_yoga_block",
      pillow: "tool_pillow",
      step_bench: "tool_step_bench",
    }

    return icons[tool];
  }

  //TODO: mover a file utils
  public static getAvatarUrl(user: any, output_s3_url: string) {
    return user.avatarOutputKey ? output_s3_url + user.avatarOutputKey + '-small.jpg' : user.avatarUrl || "";
  }

  //TODO: mover a file utils
  public static getLogoUrl(logoOutput: any, output_s3_url: string) {
    return logoOutput ? output_s3_url + logoOutput + '-small.jpg' : "";
  }

  //TODO: mover a file utils
  public static getRehubAsset(path: string, environment: any, version: string) {
    let v = "?v=" + version;
    let url = environment.assets_url ? environment.assets_url : environment.professional_url;
    return url + "/rehub-assets/" + path + v;
  }

  //TODO: mover a file utils
  public static getEditorAsset(path: string, environment: any, version: string) {
    let v = "?v=" + version;
    let url = environment.web_resources_url;
    return url + "/editor-assets/" + environment.media_version + "/" + path + v;
  }

  //TODO: mover a protocol-utils
  public static getProtocolWithoutSide(protocol: string) {
    return protocol.replace("_left", "").replace("_right", "");
  }

  //TODO: mover a protocol-utils
  public static getSideFromProtocol(protocol: string){
    return protocol.includes("left") ? "left" : protocol.includes("right") ? "right" : "";
  }

  //TODO: mover a file utils
  public static buildLegalLink(urlS3: string, fileName: string, languageCode: string, typeUser: "patient" | "professional" | null = null, version: string, isSelfService: boolean = false) {
    let environment = isSelfService ?  '/self-service/files/': '/rehub-frontend/legal/' ;
    let url = urlS3 + environment + fileName + '_' + languageCode + (typeUser ? '_' + typeUser : '') + '_' + version + '.html';
    return url;
  }

  /**
   * @param obj Objecto a copiar
   * @returns Devuelve una copia del objeto
   */
  //TODO: refactor tipos
  public static deepCopy(obj: any): any{
    if (obj instanceof Date) return new Date(obj);
    if (Array.isArray(obj)) return obj.map(elem => this.deepCopy(elem));
    if (obj instanceof Object) {
      const copy: any = {};
      Object.entries(obj).forEach(([key, value]) => copy[key] = this.deepCopy(value));
      return copy;
    }

    return obj;
  }

  /**
   * @param a Primer objeto a comparar
   * @param b Segundo objeto a comparar
   * @returns Comprueba si dos objetos tienen las mismas claves
   */
  // TODO: tests
  public static objectsHaveSameKeys(a: any, b: any) {
    var aKeys = Array.isArray(a) ? a : Object.keys(a).sort();
    var bKeys = Array.isArray(b) ? b : Object.keys(b).sort();
    return JSON.stringify(aKeys) === JSON.stringify(bKeys);
  }

  //TODO: mover
  public static getSupportUrl(params: {client?: any, supportUrl?: any, user?: any, patient?: any, lang?: any, reasonGroup?: any, reason?: any, backToLogin?: any, embed?: any, color?: any}){

    let p: any = {
      client: params.client,
      lang: params.lang,
    }

    if(params.user){
      p.iam = "professional";
      if (params.user.userName) p.name_contact = params.user.userName;
      if (params.user.familyName) p.surname = params.user.familyName;
      if (params.user.phone) p.phone = params.user.phone;
      if (params.user.email) p.email = params.user.email;
    }

    if(params.patient){
      p.iam = "patient";
      if (params.patient.patientName) p.name_contact = params.patient.patientName;
      if (params.patient.familyName) p.surname = params.patient.familyName;
      if (params.patient.phone) p.phone = params.patient.phone;
      if (params.patient.email) p.email = params.patient.email;
    }

    if (params.reasonGroup != null) p.reason_group = params.reasonGroup;
    if (params.reason != null) p.reason = params.reason;
    if (params.backToLogin != null) p.back_to_login = params.backToLogin;
    if (params.embed) p.embed = params.embed;
    if (params.color) p.color = params.color.replace("#", "");

    return this.addQueryParamsToURL(params.supportUrl, p);
  }

  /**
  * @param params Objeto con los parámetros a convertir
  * @returns Los parámetros en string con el '?' añadido
  */
  public static objectToQueryParamsString(params: any): string{
    if (params == null) return "";

    let result = Object.keys(params).map((key: any) => {
      let value = params[key];

      if(Array.isArray(value)){
        return value.map((v: any)=>{
          return `${key}=${v}`;
        }).join("&");
      }

      return `${key}=${value}`;
    }).join("&");

    return result;
  }

  /**
   * @param url URL a la que añadir los parámetros
   * @param params Objeto con los parámetros a añadir
   * @returns La URL con los parámetros añadidos
   */
  public static addQueryParamsToURL(url: string, params: any){
    let queryParamsString = this.objectToQueryParamsString(params);

    if(this.hasQueryParamsInURL(url)){
      return url + "&" + queryParamsString;
    }else if(queryParamsString){
      return url + "?" + queryParamsString;
    }else{
      return url;
    }
  }

  /**
   * @param url URL a comprobar
   * @returns Comprueba si la URL tiene parámetros
   */
  public static hasQueryParamsInURL(url: string): boolean{
    let pattern = new RegExp(/\?.+=.*/g);
    return pattern.test(url);
  }

  /**
   * @param params Objeto con los parámetros a limpiar
   * @returns Objeto con los parámetros limpios, es decir, sin valores nulos, vacíos o arrays vacíos
   */
  public static cleanQueryParams(params: any){
    if(!params || typeof params !== 'object') return null;

    let cleanParams: any = {};

    Object.keys(params).forEach((key: string)=>{
      if(
        params[key] !== null &&
        params[key] !== "" &&
        !(Array.isArray(params[key]) && params[key].length == 0)
      ){
        cleanParams[key] = params[key];
      }
    });

    return cleanParams;
  }

  /**
   * @param value Valor a comprobar
   * @returns Si el valor es un array, lo devuelve tal cual. Si no lo es, lo devuelve en un array
  */
  // TODO! añadir tests
  public static toArrayIfNotArray(value: any){
    if(value == null) return [];
    if(!Array.isArray(value)) return [value];
    return value;
  }

  /**
   * @param text Texto a limpiar de acentos
   * @returns Texto sin acentos, sustituyendo las vocales con acento por vocales sin acento
   */
  public static stripAccents(text: string) {
    text = text.toLowerCase();

    Object.keys(ACCENTS).forEach((c) => {
      text = text.replace(new RegExp("[" + ACCENTS[c] + "]", "g"), c);
    });

    return text;
  }

  /**
   * @param text Texto a comprobar
   * @returns Comprueba si el texto indicado tiene acentos
   */
  public static hasAccents(text: string) {
    text = text.toLowerCase();
    let found: boolean = false;
    let accentKeys = Object.keys(ACCENTS);
    let i = 0, lenKeys = accentKeys.length;
    while(i < lenKeys && !found) {
      const regExp = new RegExp("[" + ACCENTS[accentKeys[i]]+ "]", "g");
      found = regExp.test(text);
      i++;
    }
    return found;
  }

  /**
   * @param text Texto a capitalizar
   * @returns Texto capitalizado, es decir, la primera letra en mayúscula y el resto en minúscula
   */
  public static capitalize(text:string) {
    if (!text) return text;
    return text[0].toUpperCase() + text.substr(1).toLowerCase();
  }

  /**
   * @param name Nombre del parámetro a buscar en la URL
   * @returns Valor del parámetro indicado en la URL
   */
  public static getUrlParameter(name: string) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  };

  /**
   * @param xml Fichero XML en formato string
   * @param key Nombre de la etiqueta a buscar en el XML
   * @returns Valor de la etiqueta
   */
  public static getXMLValueFromString(xml: string, key: string) {
    let xmlDoc: any = new DOMParser().parseFromString(xml, "text/xml");
    return xmlDoc.getElementsByTagName(key)[0].childNodes[0].nodeValue;
  }


  /**
   * @param number Número a formatear
   * @param language Idioma en el que se formateará el número (english, spanish)
   * @returns Número formateado según el idioma indicado
   */
  public static getNumberFormat(number: number | string | undefined | null, language: string): string {
    if (number == null) return "";
    if (typeof number === 'string') number = Number.parseFloat(number);
    if (!Number.isFinite(number)) return "";

    let locale = language == "english" ? "en-US" : "es-ES";
    let options = {minimumFractionDigits: 0, maximumFractionDigits: 2};
    let numberFormat = new Intl.NumberFormat(locale, options);

    return numberFormat.format(number);
  }

  /**
   * @description Número parseado a float (si el número es un string, sustituye las comas por puntos)
   * @param number Número a parsear
   * @returns Número parseado a float (si el número es un string, sustituye las comas por puntos)
   */
  public static parseFloat(number: string | number): number {
    if(typeof number == "number") return number;

    return Number.parseFloat(number.replace(",", "."));
  }

  /**
   * @param a Primer objeto a comparar
   * @param b Segundo objeto a comparar
   * @returns Comprueba si los dos objetos son iguales, es decir, si tienen las mismas propiedades y valores
   */
  public static objectEquals(a: any, b: any): boolean {
    if (a === b) return true;

    if (a instanceof Date && b instanceof Date) return a.getTime() === b.getTime();

    if (!a || !b || (typeof a !== 'object' && typeof b !== 'object')) return a === b;

    if (a.prototype !== b.prototype) return false;

    const keys = Object.keys(a);

    if (keys.length !== Object.keys(b).length) return false;
    return keys.every(k => this.objectEquals(a[k], b[k]));
  }

  /**
   * @param float Número a formatear
   * @param decimals Número de decimales a mostrar
   * @returns Número formateado con el número de decimales indicado
   */
  public static floatToFixed(float: number, decimals: number = 2): number{
    return parseFloat(float.toFixed(decimals));
  }

  //TODO: eliminar? mover time-utils?
  public static getMilliseconds(seconds: number, minutes: number = 0, hours: number = 0) {
    let milliseconds = 0;

    if (seconds > 0) milliseconds += seconds * 1000;
    if (minutes > 0) milliseconds += minutes * 60 * 1000;
    if (hours > 0) milliseconds += hours * 60 * 60 * 1000;

    return Math.max(milliseconds, 1000);
  }

  /**
   * @param src Ruta del script a cargar
   * @returns Promesa que se resuelve cuando se ha cargado el script
   */
  public static loadScript(src: string): Promise<void>{
    return new Promise((resolve: any)=>{
      let script = document.createElement('script');
      script.onload = ()=>{resolve()};
      script.src = src;
      document.head.appendChild(script);
    });
  }

  /**
   * @param srcs Rutas de los scripts a cargar
   * @returns Promesa que se resuelve cuando se han cargado todos los scripts en paralelo
   */
  public static loadScripts(srcs: string[]): Promise<void[]>{
    let promises = srcs.map((src: string)=>this.loadScript(src));
    return Promise.all(promises);
  }

  /**
   *
   * @param srcs Rutas de los scripts a cargar
   * @returns Promesa que se resuelve cuando se han cargado todos los scripts, cargando de uno en uno
   */
  public static loadScriptsChained(srcs: string[]): Promise<void>{
    let promises = srcs.map((src: string)=>this.loadScript(src));

    let load = (resolve: any, reject: any)=>{
      console.log(srcs[0]);
      promises[0].then(()=>{
        promises.shift();
        srcs.shift();
        if(promises.length > 0) setTimeout(()=>{load(resolve, reject)}, 1000);
        else resolve();
      });
    }

    return new Promise((resolve: any, reject: any)=>{
      load(resolve, reject)
    });
  }

  /**
   * @param src Ruta del script a comprobar
   * @returns Comprueba si el script ya ha sido cargado
   */
  public static isScriptLoaded(src: string): boolean {
    let scripts: any = Array.from(document.getElementsByTagName('script'));
    if (scripts && scripts.length > 0) {
      return scripts.some((script: any) => script.src == src);
    }
    return false;
  }

  //TODO: mover time-utils?
  public static formatCalendarEvents(events: any) {
    let groupEventsByDay = events.reduce((result: any, currentValue: any) => {
      result[currentValue.fromDate] = result[currentValue.fromDate] || [];
      result[currentValue.fromDate] += currentValue;
      return result;
    }, {});

    let formatedEvents: any = {};

    Object.keys(groupEventsByDay).forEach((key: string) => {
      formatedEvents[key] = { color: "var(--color-primary)" };
    });

    return formatedEvents;
  }

  /**
   * @param token Token a parsear
   * @returns Objeto con los datos parseados del token
   */
  public static parseJwt(token: string){
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

 /**
  *
  * @param seconds Segundos introducidos como contador de tiempo
  * @returns String que formatea dichos segundos en 'h' horas ,'m' minutos,'s' segundos y lo concatena (Ex: 3666seg -> 01:01:06)
  */
  //TODO: mover time-utils?
  public static formatSecondsToHoursAndMinutes(seconds: number, showHours: boolean = false): string {

    let h = Math.floor(seconds / 3600);
    let m = Math.floor(seconds % 3600 / 60);
    let s = Math.floor(seconds % 3600 % 60);

    let result = [this.appendZerosToNumber(m, 2), this.appendZerosToNumber(s, 2)];

    if (h > 0 || showHours) result = [this.appendZerosToNumber(h, 2), ...result];

    return result.join(':');
  }
}