import { TherapyAPI } from "../api";
import { SharedDeployInfoService } from "../service/deploy-info.service";
import { SharedI18nService } from "../service/i18n.service";
import { ExerciseUtils } from "./exercise-utils";
import { SharedUtils } from "./utils";

export type MusculoskeletalGoal = "rom" | "strength" | "stability" | "speed" | "proprioception" | "stretching";
export type LogopedicsGoal = "agility" | "strengthening" | "toning" | "function";
export type RespiratoryGoal = "strengthening" | "oxygenation" | "lungcapacity" | "controldyspnea" | "endurance" | "resistance";
export type PelvicFloorGoal= "urinary_continence" | "fecal_continence" | "pain_reduction" | "sexual_function" | "proprioception" | "lumbopelvic";
export type Goal = MusculoskeletalGoal | LogopedicsGoal | RespiratoryGoal | PelvicFloorGoal;
export type GoalGroups = "strength" | "mobility" | "stretching" | "balance_coordination" | "pain_modulation" | "aerobic" | PelvicFloorGoal | LogopedicsGoal | "lung_capacity" | "control_dyspnea" | "compliance" | "strengthening"
export type Pathologies = "low_back_pain" | "patellofemoral_syndrome" | "ankle_sprain" | "neck_pain" | "rotator_cuff" | "osteoarthritis" | "asthma" | "epoc" | "chronic_bronchitis" | "post_covid";
export type ExerciseType = "stretching" | "squat" | "lunge" | "plank" | "deadlift" | "row" | "press" | "bridge" | "jump" | "pnf" | "walking" | "neurodinamic" | "myofacial_release" | "self_massage" | "hipopresive" | "self_asistent" | "isometric" | "kegel" | "funtional";
export type SensorType = "MAIN" | "HANDLE" | "PRESSURE";
export type Tool = "strength" | "stick" | "ball" | "weight" | "chair" | "roll" | "towel" | "table" | "ribbon_rope" | "little_ball" | "yoga_block" | "pillow" | "step_bench" | "empty" | "elements";
export type Position = "standing" | "sitting" | "quadrupedia" | "prone" | "supine" | "kneeling" | "side-lying" | "monopodal" | "other";
export type Movement = "flexion" | "extension" | "abduction" | "adduction" | "inversion" | "eversion" | "inclination" | "rotation" | "other";
export type JointCategory = "musculoskeletal" | "logopedics" | "respiratory" | "pelvicFloor";
export type SignalName = "Pitch" | "Roll" | "Yaw" | "Goniometer" | "Strength";
export type SensorPosition = "x_p" | "y_p" | "z_p" | "x_n" | "y_n" | "z_n";
export type SignalShape = "bidirectional"| "mountain" | "valley";
export type CalcMode = "frontal" | "sagittal" | "transverse";
export type CamPlane = "up_frontal" | "down_frontal" | "up_lateral" | "down_lateral" | "general_frontal" | "general_lateral";
export type CamPlaneExtremity = "up" | "down" | "general";
export type CamPlanePlane = "lateral" | "frontal";
export type BandColor = "yellow" | "red" | "green" | "blue" | "black";
export type CameraModel = "BODY_MODEL" | "HAND_MODEL";
export type JointPart = "upperLimb" | "lowerLimb" | "trunk";
export type Joint = "shoulder" | "elbow" | "wrist" | "hip" | "knee" | "ankle" | "lumbar" | "neck";

export type ExerciseInstructionsFromFile = {
  info: string[]
  warning: string[]
}

export type Degrees = {
  Pitch?: SignalDegrees,
  Roll?: SignalDegrees,
  Yaw?: SignalDegrees,
  Goniometer?: SignalDegrees,
  Strength?: SignalDegrees
}

export type SignalDegrees = {
  maxDegree: number,
  minDegree: number,
  minViewDegree?: number,
  maxViewDegree?: number
}

export type RehubJoint = {
  category: string,
  enabled: boolean,
  part: string,
  pathologies: string[],
  sides: string[],
  slug: string
}

export type I18nSignals = {
  Pitch?: string,
  Roll?: string,
  Yaw?: string,
  Goniometer?: string,
  Strength?: string
}

export type RehubProtocol = {
  sortKey?: string,

  protocolId?: string,
  protocolName?: string,
  sensorInstructions?: string,
  exerciseInstructions?: string | ExerciseInstructionsFromFile | null,

  i18nSignalMins?: I18nSignals,
  i18nSignalMaxs?:I18nSignals,
  i18nSignalNames?: I18nSignals,
  i18nExInstWarn?: string[],
  i18nExInstInfo?: string[],

  ownerId?: string,

  contralateral?: boolean,
  contralateralVidProtocolId?: string,
  contralateralThumbProtId?: string,
  videoProtocol?: string,
  prepVideoProtocol?: string,
  thumbnailProtocol?: string,
  thumbnailUrl?: string,
  thumbnailKey?: string,
  thumbnailOutputKey?: string,
  used?: boolean,
  recordType?: string,
  signalNames?: any,

  recordId?: string,

  active?: boolean,
  chartPatient?: string,
  chartProfessional?: string,
  degrees?: Degrees,
  goal?: string[],
  inverted?: string[],
  side?: string,
  joint?: string,
  jointAngle?: string,
  mainSignal?: SignalName,
  manual?: boolean,
  movements?: string[],
  positions?:  string[],
  cameraInitialPosition?: string,
  sensorEnabled?: boolean,
  sensorPosition?: SensorPosition,
  sensorType?: string | null,
  signalShape?: SignalShape,
  signals?: string[],
  slug?: string,
  tools?: string[],
  stages?: string[],
  videoType?: string,
  iterationTime?: number,
  protocolSource?: string,
  doneDate?: string,
  itemType?: string,
  creatorId?: string,
  bilateral?: boolean,
  cameraEnabled?: boolean,
  limitation?: string,
  muscleGroups?: string[],
  selfService?: boolean,

  releaseVersion?: string,
  mediaVersion?: string,
  version?: string,

  fromJSON?: boolean,

  cam_plane?: CamPlane,
  cam_mainMovement?: CameraTrackingMainMovement,
  cam_lock_plane?: boolean,
  cam_secondaryMovements?: CameraTrackingSecondaryMovement[],
  cam_offsets?: CameraTrackingCompensation[],
  cam_model?: CameraModel,
};

export type CameraTrackingMovementBase = {
  initial: number,
  final: number,
  calcMode: CalcMode
}

export type CameraTrackingMovementMinMax = {
  min: number,
  max: number
}

export type CameraTrackingMainMovement = CameraTrackingMovementBase & CameraTrackingMovementMinMax & {
  inverted?: boolean,
  draw_initial: number,
  draw_final: number,
}

export type CameraTrackingSecondaryMovement = CameraTrackingMovementBase & CameraTrackingMovementMinMax;

export type CameraTrackingCompensation = CameraTrackingMovementBase & {
  angle: number
}

export type I18nItem = {
  itemId?: string,
  sortKey?: string,

  text_es?: string,
  text_en?: string,
  text_it?: string,

  status_es?: string,
  status_en?: string,
  status_it?: string
}

export const CameraProtocolSuggestionsByJoint = {
  elbow_left: {
    cam_model: "BODY_MODEL",
    segment: "7_9",
    plane: "up_frontal",
    calcMode: "frontal",
    movements: {
      flexion_extension: {plane: "up_lateral", calcMode: "sagittal"},
      rotation: {plane: "up_frontal" , calcMode: "transverse"}
    }
  },
  shoulder_left: {
    cam_model: "BODY_MODEL",
    segment: "5_7",
    plane: "up_frontal",
    calcMode: "frontal",
    movements: {
      flexion_extension: {plane: "up_lateral", calcMode: "sagittal"},
      abduction_adduction: {plane: "up_frontal", calcMode: "frontal"},
      rotation: {plane: "up_frontal" , calcMode: "transverse"},
      inclination: {plane: "up_frontal", calcMode: "frontal"}
    }
  },
  hip_left: {
    cam_model: "BODY_MODEL",
    segment: "11_13",
    plane: "down_frontal",
    calcMode: "frontal",
    movements: {
      flexion_extension: {plane: "down_lateral" , calcMode: "sagittal"},
      abduction_adduction: {plane: "down_frontal", calcMode: "frontal"},
      rotation: {plane: "down_frontal" , calcMode: "transverse"},
      inclination: {plane: "down_frontal", calcMode: "frontal"}
    }
  },
  knee_left: {
    cam_model: "BODY_MODEL",
    segment: "13_15",
    plane: "down_frontal",
    calcMode: "frontal",
    movements: {
      flexion_extension: {plane: "down_lateral" , calcMode: "sagittal"},
      rotation: {plane: "down_frontal" , calcMode: "transverse"}
    }
  },
  elbow_right: {
    cam_model: "BODY_MODEL",
    segment: "8_10",
    plane: "up_frontal",
    calcMode: "frontal",
    movements: {
      flexion_extension: {plane: "up_lateral" , calcMode: "sagittal"},
      rotation: {plane: "up_frontal" , calcMode: "transverse"}
    }
  },
  shoulder_right: {
    cam_model: "BODY_MODEL",
    segment: "6_8",
    plane: "up_frontal",
    calcMode: "frontal",
    movements: {
      flexion_extension: {plane: "up_lateral", calcMode: "sagittal"},
      abduction_adduction: {plane: "up_frontal", calcMode: "frontal"},
      rotation: {plane: "up_frontal" , calcMode: "transverse"},
      inclination: {plane: "up_frontal", calcMode: "frontal"}
    }
  },
  hip_right: {
    cam_model: "BODY_MODEL",
    segment: "12_14",
    plane: "down_frontal",
    calcMode: "frontal",
    movements: {
      flexion_extension: {plane: "down_lateral", calcMode: "sagittal"},
      abduction_adduction: {plane: "down_frontal" , calcMode: "frontal"},
      rotation: {plane: "down_frontal" , calcMode: "transverse"},
      inclination: {plane: "down_frontal", calcMode: "frontal"}
    },
  },
  knee_right: {
    cam_model: "BODY_MODEL",
    segment: "14_16",
    plane: "down_frontal",
    calcMode: "frontal",
    movements: {
      flexion_extension: {plane: "down_lateral", calcMode: "sagittal"},
      rotation: {plane: "down_frontal" , calcMode: "transverse"}
    }
  },
  lumbar: {
    cam_model: "BODY_MODEL",
    segment: "18_17",
    plane: "up_frontal",
    calcMode: "frontal",
    movements: {
      flexion_extension: {plane: "up_lateral", calcMode: "sagittal"},
      abduction_adduction: {plane: "up_frontal", calcMode: "frontal"},
      rotation: {plane: "up_frontal" , calcMode: "transverse"},
      inclination: {plane: "up_frontal", calcMode: "frontal"}
    }
  },
  neck: {
    cam_model: "BODY_MODEL",
    segment: "17_0",
    plane: "up_frontal",
    calcMode: "frontal",
    movements: {
      flexion_extension: {plane: "up_lateral", calcMode: "sagittal"},
      abduction_adduction: {plane: "up_frontal", calcMode: "frontal"},
      rotation: {plane: "up_frontal" , calcMode: "transverse"},
      inclination: {plane: "up_frontal", calcMode: "frontal"}
    }
  },
  wrist_left: {
    cam_model: "HAND_MODEL"
  },
  wrist_right: {
    cam_model: "HAND_MODEL"
  }
}

export const CameraPoints = {
  NOSE: 0,
  LEFT_EYE: 1,
  RIGHT_EYE: 2,
  LEFT_EAR: 3,
  RIGHT_EAR: 4,
  LEFT_SHOULDER: 5,
  RIGHT_SHOULDER: 6,
  LEFT_ELBOW: 7,
  RIGHT_ELBOW: 8,
  LEFT_WRIST: 9,
  RIGHT_WRIST: 10,
  LEFT_HIP: 11,
  RIGHT_HIP: 12,
  LEFT_KNEE: 13,
  RIGHT_KNEE: 14,
  LEFT_ANKLE: 15,
  RIGHT_ANKLE: 16,
  MID_SHOULDER: 17,
  MID_HIP: 18,
  LUMBAR: 19
}

export const CameraHandPoints = {
  WRIST_RIGHT: 0,
  THUMB_CMC_RIGHT: 1,
  THUMB_MCP_RIGHT: 2,
  THUMB_IP_RIGHT: 3,
  THUMB_TIP_RIGHT: 4,
  INDEX_FINGER_MCP_RIGHT: 5,
  INDEX_FINGER_PIP_RIGHT: 6,
  INDEX_FINGER_DIP_RIGHT: 7,
  INDEX_FINGER_TIP_RIGHT: 8,
  MIDDLE_FINGER_MCP_RIGHT: 9,
  MIDDLE_FINGER_PIP_RIGHT: 10,
  MIDDLE_FINGER_DIP_RIGHT: 11,
  MIDDLE_FINGER_TIP_RIGHT: 12,
  RING_FINGER_MCP_RIGHT: 13,
  RING_FINGER_PIP_RIGHT: 14,
  RING_FINGER_DIP_RIGHT: 15,
  RING_FINGER_TIP_RIGHT: 16,
  PINKY_MCP_RIGHT: 17,
  PINKY_PIP_RIGHT: 18,
  PINKY_DIP_RIGHT: 19,
  PINKY_TIP_RIGHT: 20,
  WRIST_LEFT: 21,
  THUMB_CMC_LEFT: 22,
  THUMB_MCP_LEFT: 23,
  THUMB_IP_LEFT: 24,
  THUMB_TIP_LEFT: 25,
  INDEX_FINGER_MCP_LEFT: 26,
  INDEX_FINGER_PIP_LEFT: 27,
  INDEX_FINGER_DIP_LEFT: 28,
  INDEX_FINGER_TIP_LEFT: 29,
  MIDDLE_FINGER_MCP_LEFT: 30,
  MIDDLE_FINGER_PIP_LEFT: 31,
  MIDDLE_FINGER_DIP_LEFT: 32,
  MIDDLE_FINGER_TIP_LEFT: 33,
  RING_FINGER_MCP_LEFT: 34,
  RING_FINGER_PIP_LEFT: 35,
  RING_FINGER_DIP_LEFT: 36,
  RING_FINGER_TIP_LEFT: 37,
  PINKY_MCP_LEFT: 38,
  PINKY_PIP_LEFT: 39,
  PINKY_DIP_LEFT: 40,
  PINKY_TIP_LEFT: 41
}

export const CameraPointsContralateral = {
  NOSE: CameraPoints.NOSE,
  LEFT_EYE: CameraPoints.RIGHT_EYE,
  RIGHT_EYE: CameraPoints.LEFT_EYE,
  LEFT_EAR: CameraPoints.RIGHT_EAR,
  RIGHT_EAR: CameraPoints.LEFT_EAR,
  LEFT_SHOULDER: CameraPoints.RIGHT_SHOULDER,
  RIGHT_SHOULDER: CameraPoints.LEFT_SHOULDER,
  LEFT_ELBOW: CameraPoints.RIGHT_ELBOW,
  RIGHT_ELBOW: CameraPoints.LEFT_ELBOW,
  LEFT_WRIST: CameraPoints.RIGHT_WRIST,
  RIGHT_WRIST: CameraPoints.LEFT_WRIST,
  LEFT_HIP: CameraPoints.RIGHT_HIP,
  RIGHT_HIP: CameraPoints.LEFT_HIP,
  LEFT_KNEE: CameraPoints.RIGHT_KNEE,
  RIGHT_KNEE: CameraPoints.LEFT_KNEE,
  LEFT_ANKLE: CameraPoints.RIGHT_ANKLE,
  RIGHT_ANKLE: CameraPoints.LEFT_ANKLE,
  MID_SHOULDER: CameraPoints.MID_SHOULDER,
  MID_HIP: CameraPoints.MID_HIP,
  LUMBAR: CameraPoints.LUMBAR
}
export const CameraHandPointsContralateral = {
  WRIST_RIGHT: CameraHandPoints.WRIST_LEFT,
  THUMB_CMC_RIGHT: CameraHandPoints.THUMB_CMC_LEFT,
  THUMB_MCP_RIGHT:CameraHandPoints.THUMB_MCP_LEFT,
  THUMB_IP_RIGHT:CameraHandPoints.THUMB_IP_LEFT,
  THUMB_TIP_RIGHT:CameraHandPoints.THUMB_TIP_LEFT,
  INDEX_FINGER_MCP_RIGHT:CameraHandPoints.INDEX_FINGER_MCP_LEFT,
  INDEX_FINGER_PIP_RIGHT:CameraHandPoints.INDEX_FINGER_PIP_LEFT,
  INDEX_FINGER_DIP_RIGHT:CameraHandPoints.INDEX_FINGER_DIP_LEFT,
  INDEX_FINGER_TIP_RIGHT:CameraHandPoints.INDEX_FINGER_TIP_LEFT,
  MIDDLE_FINGER_MCP_RIGHT:CameraHandPoints.MIDDLE_FINGER_MCP_LEFT,
  MIDDLE_FINGER_PIP_RIGHT: CameraHandPoints.MIDDLE_FINGER_PIP_LEFT,
  MIDDLE_FINGER_DIP_RIGHT: CameraHandPoints.MIDDLE_FINGER_DIP_LEFT,
  MIDDLE_FINGER_TIP_RIGHT: CameraHandPoints.MIDDLE_FINGER_TIP_LEFT,
  RING_FINGER_MCP_RIGHT: CameraHandPoints.RING_FINGER_MCP_LEFT,
  RING_FINGER_PIP_RIGHT: CameraHandPoints.RING_FINGER_PIP_LEFT,
  RING_FINGER_DIP_RIGHT: CameraHandPoints.RING_FINGER_DIP_LEFT,
  RING_FINGER_TIP_RIGHT: CameraHandPoints.RING_FINGER_TIP_LEFT,
  PINKY_MCP_RIGHT: CameraHandPoints.PINKY_MCP_LEFT,
  PINKY_PIP_RIGHT: CameraHandPoints.PINKY_PIP_LEFT,
  PINKY_DIP_RIGHT: CameraHandPoints.PINKY_DIP_LEFT,
  PINKY_TIP_RIGHT: CameraHandPoints.PINKY_TIP_LEFT,
  WRIST_LEFT: CameraHandPoints.WRIST_RIGHT,
  THUMB_CMC_LEFT: CameraHandPoints.THUMB_CMC_RIGHT,
  THUMB_MCP_LEFT: CameraHandPoints.THUMB_MCP_RIGHT,
  THUMB_IP_LEFT: CameraHandPoints.THUMB_IP_RIGHT,
  THUMB_TIP_LEFT: CameraHandPoints.THUMB_TIP_RIGHT,
  INDEX_FINGER_MCP_LEFT: CameraHandPoints.INDEX_FINGER_MCP_RIGHT,
  INDEX_FINGER_PIP_LEFT: CameraHandPoints.INDEX_FINGER_PIP_RIGHT,
  INDEX_FINGER_DIP_LEFT: CameraHandPoints.INDEX_FINGER_DIP_RIGHT,
  INDEX_FINGER_TIP_LEFT: CameraHandPoints.INDEX_FINGER_TIP_RIGHT,
  MIDDLE_FINGER_MCP_LEFT: CameraHandPoints.MIDDLE_FINGER_MCP_RIGHT,
  MIDDLE_FINGER_PIP_LEFT: CameraHandPoints.MIDDLE_FINGER_PIP_RIGHT,
  MIDDLE_FINGER_DIP_LEFT: CameraHandPoints.MIDDLE_FINGER_DIP_RIGHT,
  MIDDLE_FINGER_TIP_LEFT: CameraHandPoints.MIDDLE_FINGER_TIP_RIGHT,
  RING_FINGER_MCP_LEFT: CameraHandPoints.RING_FINGER_MCP_RIGHT,
  RING_FINGER_PIP_LEFT: CameraHandPoints.RING_FINGER_PIP_RIGHT,
  RING_FINGER_DIP_LEFT: CameraHandPoints.RING_FINGER_DIP_RIGHT,
  RING_FINGER_TIP_LEFT: CameraHandPoints.RING_FINGER_TIP_RIGHT,
  PINKY_MCP_LEFT: CameraHandPoints.PINKY_MCP_RIGHT,
  PINKY_PIP_LEFT: CameraHandPoints.PINKY_PIP_RIGHT,
  PINKY_DIP_LEFT: CameraHandPoints.PINKY_DIP_RIGHT,
  PINKY_TIP_LEFT:  CameraHandPoints.PINKY_TIP_RIGHT
}

export const CameraSegments: {[key: string]: number[]} = {
  "17_0": [CameraPoints.MID_SHOULDER, CameraPoints.NOSE],
  "6_5": [CameraPoints.RIGHT_SHOULDER, CameraPoints.LEFT_SHOULDER],
  "18_17": [CameraPoints.MID_HIP, CameraPoints.MID_SHOULDER],
  "6_8": [CameraPoints.RIGHT_SHOULDER, CameraPoints.RIGHT_ELBOW],
  "6_10": [CameraPoints.RIGHT_SHOULDER, CameraPoints.RIGHT_WRIST],
  "8_10": [CameraPoints.RIGHT_ELBOW, CameraPoints.RIGHT_WRIST],
  "5_7": [CameraPoints.LEFT_SHOULDER, CameraPoints.LEFT_ELBOW],
  "5_9": [CameraPoints.LEFT_SHOULDER, CameraPoints.LEFT_WRIST],
  "7_9": [CameraPoints.LEFT_ELBOW, CameraPoints.LEFT_WRIST],
  "12_11": [CameraPoints.RIGHT_HIP, CameraPoints.LEFT_HIP],
  "12_14": [CameraPoints.RIGHT_HIP, CameraPoints.RIGHT_KNEE],
  "14_16": [CameraPoints.RIGHT_KNEE, CameraPoints.RIGHT_ANKLE],
  "12_16": [CameraPoints.RIGHT_HIP, CameraPoints.RIGHT_ANKLE],
  "11_13": [CameraPoints.LEFT_HIP, CameraPoints.LEFT_KNEE],
  "13_15": [CameraPoints.LEFT_KNEE, CameraPoints.LEFT_ANKLE],
  "11_15": [CameraPoints.LEFT_HIP, CameraPoints.LEFT_ANKLE]
}

export const CameraSegmentsContralateral: {[key: string]: number[]} = {
  "17_0": CameraSegments["17_0"],
  "6_5": CameraSegments["6_5"],
  "18_17": CameraSegments["18_17"],
  "6_8": CameraSegments["5_7"],
  "6_10": CameraSegments["5_9"],
  "8_10": CameraSegments["7_9"],
  "5_7": CameraSegments["6_8"],
  "5_9": CameraSegments["6_10"],
  "7_9": CameraSegments["8_10"],
  "12_11": CameraSegments["12_11"],
  "12_14": CameraSegments["11_13"],
  "14_16": CameraSegments["13_15"],
  "12_16": CameraSegments["11_15"],
  "11_13": CameraSegments["12_14"],
  "13_15": CameraSegments["14_16"],
  "11_15": CameraSegments["12_16"]
}

export const CameraSegmentsByExtremity: {[key: string]: string[]} = {
  up: ["17_0", "6_5", "18_17", "6_8", "6_10", "8_10", "5_7", "5_9", "7_9"],
  down: ["12_11", "12_14", "14_16", "12_16", "11_13", "13_15", "11_15"],
  general: ["17_0", "6_5", "18_17", "6_8", "6_10", "8_10", "5_7", "5_9", "7_9", "12_11", "12_14", "14_16", "12_16", "11_13", "13_15", "11_15"]
}

export const CameraPointsByCamPlane = {
  up: [CameraPoints.NOSE, CameraPoints.LEFT_EYE, CameraPoints.RIGHT_EYE, CameraPoints.LEFT_EAR, CameraPoints.RIGHT_EAR, CameraPoints.LEFT_SHOULDER, CameraPoints.RIGHT_SHOULDER, CameraPoints.LEFT_ELBOW, CameraPoints.RIGHT_ELBOW, CameraPoints.LEFT_WRIST, CameraPoints.RIGHT_WRIST, CameraPoints.MID_SHOULDER, CameraPoints.MID_HIP, CameraPoints.LUMBAR],
  down: [CameraPoints.LEFT_HIP, CameraPoints.RIGHT_HIP, CameraPoints.LEFT_KNEE, CameraPoints.RIGHT_KNEE, CameraPoints.LEFT_ANKLE, CameraPoints.RIGHT_ANKLE],
}

export class ProtocolUtils{

  public static VIEW_DEGREE_MARGIN = 30;
  public static VIEW_DEGREE_MARGIN_STRENGTH = 3;
  public static MARGIN_THERAPY: number = 15;
  public static BIDRECTIONAL_MARGIN  = 5;

  static getJointTranslation(i18nService: SharedI18nService, joint: string){
    return i18nService.translate("joint_" + joint);
  }

  static getProtocolTranslation(i18nService: SharedI18nService, protocol: any, withJoint: boolean = false){
    let protocolTranslation = protocol.protocolName ? protocol.protocolName : i18nService.translate(protocol.slug + "_name");

    if(withJoint){
      let jointTranslation = this.getJointTranslation(i18nService, protocol.joint);
      return jointTranslation + ": " + protocolTranslation;
    }else return protocolTranslation;
  }

  //createProtocolFromRecord
  static getProtocolFromRecord(record: any, protocol: RehubProtocol){
    if(record.mathbox.Strength.MaxPointMean < 1){
      let index = protocol.signals!.indexOf("Strength");
      protocol.signals!.splice(index, 1);
    }

    protocol.recordId = record.recordId;
    protocol.degrees = this.getDefaultProtocolDegrees(record.mathbox);

    if(record.mainSignal) protocol.mainSignal = record.mainSignal;
    if(record.sensorPosition) protocol.sensorPosition = record.sensorPosition;
    if(record.signalShape) protocol.signalShape = record.signalShape;

    return protocol;
  }

  /**
   *
   * @param translateService
   * @param exerciseInstructions Fichero de instrucciones de ejercicio
   * @param protocol
   * @returns
   */
  static getDuplicatedProtocol(translateService: SharedI18nService, exerciseInstructions: any, protocol: RehubProtocol, centerId :string){
    let isCustom = this.isCustomFromSlug(protocol.slug!);
    let result: RehubProtocol = SharedUtils.deepCopy(protocol);

    result.recordType = "CUSTOM_PROTOCOL";

    delete result.thumbnailUrl;
    delete result.thumbnailKey;
    if(result.ownerId == centerId)  result.ownerId = "CENTER";
    else delete result.ownerId;
    delete result.used;
    delete result.protocolId;

    if(result.sensorType == null) delete result.sensorType;
    if(result.active === undefined) result.active = false;

    if (!result.exerciseInstructions && !isCustom) {
      result.exerciseInstructions = this.getDuplicatedProtocolExerciseInstructions(translateService, exerciseInstructions[protocol.slug!]);
    } else if (result.exerciseInstructions == null) {
      delete result.exerciseInstructions;
    }

    if (!isCustom) result.thumbnailProtocol = protocol.slug;
    if (!protocol.videoProtocol) result.videoProtocol = protocol.slug;
    if (!protocol.prepVideoProtocol) result.prepVideoProtocol = protocol.slug;

    if (!result.signalNames) result.signalNames = {};

    return result;
  }

  /**
   * @param i18nService Servicio de internacionalización
   * @param exerciseInstructions Instrucciones de un ejercicio
   * @returns Las instrucciones traducidas del ejercicio
   */
  private static getDuplicatedProtocolExerciseInstructions(i18nService: SharedI18nService, exerciseInstructions: ExerciseInstructionsFromFile): ExerciseInstructionsFromFile | null{
    if (!exerciseInstructions) return null;

    if (exerciseInstructions.info) exerciseInstructions.info = exerciseInstructions.info.map((info: any)=>i18nService.translate(info));
    if (exerciseInstructions.warning) exerciseInstructions.warning = exerciseInstructions.warning.map((warning: any)=>i18nService.translate(warning));

    return exerciseInstructions;
  }

  public static getDefaultProtocol(sensorType: string | null = null) {

    let sensorEnabled = sensorType != null;

    let data: any = {
      joint: "",
      protocolName: "",
      positions: [],
      movements: [],
      tools: [],
      goal: [],
      sensorEnabled: sensorEnabled,
      cameraEnabled: false,
      muscleGroups: [],
      videoType: "DEFAULT"
    }

    if(sensorEnabled){
      data = {
        ...data,

        active: false,
        chartPatient: "MOVING_SIN_HORIZONTAL",
        chartProfessional: "STATIC_ARC_RIGHT",
        degrees: this.getDefaultProtocolDegrees(),
        inverted: [],
        signalNames: {},
        signals: ["Pitch", "Roll", "Yaw"],

        sensorInstructions: "",
        sensorType: sensorType,

        //Se usan en el realtime
        min: -180,
        max: 180,
        minView: -180,
        maxView: 180
      }

      if (sensorType == "MAIN") {
        data.signals.push("Strength");
      }else if (sensorType == "PRESSURE") {
        data.signals = ["Strength"];
        data.mainSignal = "Strength";
      }

    }

    return data;
  }

  public static getDefaultRehubEditorProtocol(): RehubProtocol {
    return {
      protocolName: "",
      joint: "",
      slug: "",
      movements: [],
      positions: [],
      goal: [],
      tools: [],
      stages: ["wip"],
      videoType: "DEFAULT",
      sensorEnabled: false,
      i18nExInstWarn: ["", "", "", "", "", ""],
      i18nExInstInfo: ["", "", "", "", "", ""],
      muscleGroups: []

      // i18nSignalNames: {},
      // i18nSignalMaxs: {},
      // i18nSignalMins: {},
      // inverted: [],
      // active: false,
      // chartPatient: "MOVING_ARC_LEFT",
      // chartProfessional: "STATIC_ARC_LEFT",
      // degrees: this.getDefaultProtocolDegrees(),
      // jointAngle: "default",
      // mainSignal: "Pitch",
      // sensorInstructions: "",
      // signalShape: "mountain",
      // signals: ["Pitch"]

    };
  }

  public static getRehubEditorProtocolFromJSON(protocol: any){
    let result = {...protocol};

    result.stages = ["wip"];
    result.fromJSON = true;

    result.slug = "";
    delete result.protocolId;

    return result;
  }

  public static getDefaultProtocolDegrees(mathbox: any = null) {
    let protocolDegrees: any = {};

    this.getSignals().forEach((signal: string) => {
      let min = mathbox && mathbox[signal] ? mathbox[signal].MinPointMean : 0;
      let max = mathbox && mathbox[signal] ? mathbox[signal].MaxPointMean : signal == "Strength" ? 20 : 180;

      protocolDegrees[signal] = {
        minDegree: min,
        maxDegree: max
      }
    });

    return protocolDegrees;
  }


  // TODO: Usar type en todos los sitios donde se llama
  public static getSignals(): SignalName[]{
    return ["Pitch", "Roll", "Yaw", "Strength"];
  }

  public static getBandColors(): BandColor[] {
    return ["yellow", "red", "green", "blue", "black"];
  }

  public static getGoalsFromJointCategory(category: JointCategory = "musculoskeletal"): Goal[] | undefined {
    if(category == "musculoskeletal") return ["rom", "strength", "stability", "speed", "proprioception", "stretching"];
    else if(category == "logopedics") return ["agility", "strengthening", "toning", "function"];
    else if(category == "respiratory") return ["strengthening", "oxygenation", "lungcapacity", "controldyspnea","endurance","resistance"];
    else if(category == "pelvicFloor") return ["urinary_continence", "fecal_continence", "pain_reduction", "sexual_function", "proprioception", "lumbopelvic"];

  }
  public static getPathologiesFromJointCategory(category: JointCategory = "musculoskeletal"): Pathologies[] | undefined {
    if (category == "musculoskeletal") return ["low_back_pain", "patellofemoral_syndrome", "ankle_sprain", "neck_pain", "rotator_cuff", "osteoarthritis"];
    else if (category == "logopedics") return [];
    else if (category == "respiratory") return ["asthma", "epoc", "chronic_bronchitis", "post_covid"];
    else if (category == "pelvicFloor") return [];

  }

  public static getGoalsGroupFromJointCategory(category: JointCategory = "musculoskeletal"): GoalGroups[] | undefined {
    if (category == "musculoskeletal") return ["strength", "mobility", "stretching", "balance_coordination", "pain_modulation", "aerobic"];
    else if (category == "logopedics") return ["agility", "strengthening", "toning", "function"];
    else if (category == "respiratory") return ["lung_capacity", "control_dyspnea", "compliance", "strengthening"];
    else if (category == "pelvicFloor") return ["urinary_continence", "fecal_continence", "pain_reduction", "sexual_function", "proprioception", "lumbopelvic"];

  }


  public static getExerciseTypesFromJointCategory(category: JointCategory = "musculoskeletal"): ExerciseType[] | undefined {
    if (category == "musculoskeletal") return ["stretching", "squat", "lunge", "plank", "deadlift", "row", "press", "bridge", "jump", "pnf", "walking", "neurodinamic", "myofacial_release", "self_massage", "hipopresive", "self_asistent", "isometric", "kegel", "funtional"];
    else if (category == "logopedics") return [];
    else if (category == "respiratory") return ["stretching", "squat", "lunge", "plank", "deadlift", "row", "press", "bridge", "jump", "pnf", "walking", "neurodinamic", "myofacial_release", "self_massage", "hipopresive", "self_asistent", "isometric", "funtional"];
    else if (category == "pelvicFloor") return ["plank", "bridge", "myofacial_release", "hipopresive", "kegel"];

  }

  public static getJointsFromJointParts(jointPart: string): Joint[] | undefined {
    if (jointPart == "upperLimb") return ["shoulder", "elbow", "wrist"];
    else if (jointPart == "lowerLimb") return ["hip", "knee", "ankle"];
    else if (jointPart == "trunk") return ["lumbar", "neck"];
  }
  public static getMuscleGroupsFromJoints(joint: string): string[] | undefined {
    if (joint == "shoulder") return ["MG_8", "MG_9", "MG_10", "MG_11", "MG_12", "MG_13", "MG_16", "MG_17", "MG_18", "MG_22"];
    else if (joint == "elbow") return ["MG_17", "MG_18"];
    else if (joint == "hip") return ["MG_5", "MG_6", "MG_7", "MG_21", "MG_23", "MG_25", "MG_26"];
    else if (joint == "neck") return ["MG_8", "MG_10", "MG_32"];
    else if (joint == "lumbar") return ["MG_5", "MG_19", "MG_20", "MG_23", "MG_24"];
    else if (joint == "wrist") return ["MG_27", "MG_28", "MG_29", "MG_30"];
    else if (joint == "knee") return ["MG_1", "MG_2", "MG_3", "MG_4", "MG_6", "MG_7", "MG_21"];
    else if (joint == "ankle") return ["MG_1", "MG_3", "MG_4"];
    else return ["MG_1", "MG_2", "MG_3", "MG_4", "MG_5", "MG_6", "MG_7", "MG_8", "MG_9", "MG_10", "MG_11", "MG_12", "MG_13", "MG_16", "MG_17", "MG_18", "MG_19", "MG_20", "MG_21", "MG_22", "MG_23", "MG_24", "MG_25", "MG_26", "MG_27", "MG_28", "MG_29", "MG_30", "MG_32"];
  }

  public static getExerciseFiltersColors() {
    return {
      jointParts: "#CCE9FF",
      joints: "#E0D9D7",
      sides: "#A3FFD0",
      goals: "#E19EFF",
      sensorKitTypes: "#FFBDD1",
      movements: "#A3E3FF",
      positions: "#efb08c",
      tools: "#DFBEAF",
      exerciseTypes: "#F4FF8F"
    }
  }

  public static getCameraPlanePlanes(): CamPlanePlane[] {
    return ["frontal", "lateral"];
  }

  public static getCameraModels(): CameraModel[] {
    return ["BODY_MODEL", "HAND_MODEL"];
  }

  public static getMovements(): Movement[]{
    return ["flexion", "extension", "abduction", "adduction", "inversion", "eversion", "inclination", "rotation", "other"];
  }

  public static getPositions(): Position[]{
    return ["standing", "sitting", "quadrupedia", "prone", "supine", "kneeling", "side-lying", "monopodal", "other"];
  }

  public static getSignalShapes(): SignalShape[]{
    return ["bidirectional", "mountain", "valley"];
  }

  //TODO! crear type
  public static getPatientCharts(): string[]{
    return ["MOVING_ARC_LEFT", "MOVING_ARC_RIGHT", "MOVING_ARC_UP", "MOVING_ARC_DOWN", "MOVING_LINE_HORIZONTAL", "MOVING_LINE_VERTICAL", "STATIC_STRENGTH"];//, "STATIC_PRESSURE"];
  }

  //TODO! crear type
  public static getProfessionalCharts(): string[]{
    return ["STATIC_ARC_LEFT", "STATIC_ARC_RIGHT", "STATIC_ARC_UP", "STATIC_ARC_DOWN", "STATIC_LINE_HORIZONTAL", "STATIC_LINE_VERTICAL", "STATIC_STRENGTH"];// "STATIC_PRESSURE"];
  }

  public static getSensorPositions(): SensorPosition[]{
    return ["x_p", "y_p", "z_p", "x_n", "y_n", "z_n"];
  }

  public static parseSensorPositions(sensorPosition: string) {
    let positions: any = {
      y_p: "upwards",
      y_n: "down",
      z_p: "to_the_left",
      z_n: "to_the_right",
    }

    return positions[sensorPosition];
  }

  public static getTools(category: JointCategory = "musculoskeletal"): Tool[] {
    if (category == "musculoskeletal") return ["strength", "stick", "ball", "weight", "chair", "roll", "towel", "table", "ribbon_rope", "little_ball", "yoga_block", "pillow", "step_bench"];
    else if (category == "logopedics") return ["elements"];
    else if (category == "respiratory") return ["strength", "stick", "ball", "weight", "chair", "roll", "towel", "table", "ribbon_rope", "little_ball", "yoga_block", "pillow", "step_bench"]
    else if (category == "pelvicFloor") return ["strength", "stick", "ball", "weight", "chair", "roll", "towel", "table", "ribbon_rope", "little_ball", "yoga_block", "pillow", "step_bench"]
  }

  public static getSensorTypes(): SensorType[]{
    return ["MAIN", "HANDLE", "PRESSURE"];
  }

  public static getAllStages(): string[]{
    return ["all", "wip", "test2", "pre", "pro", "dkv", "pilot2", "demo3", "uni2", "vitdev", "vitdem", "vitpro", "wellwo"];
  }

  //TODO! crear type
  public static getVideoTypes(): string[]{
    return ["ITERATION_TIME_SPEED", "DEFAULT"];
  }

  public static getMuscleGroups(): string[]{
    let num = 32;
    let result = [];

    for(let i = 1; i <= num; i++){
      result.push("MG_" + i);
    }

    return result;
  }

  // TODO crear type joint?
  public static getLimitations(joint: string){
    if(joint == "logoDysphagia") return ["0", "1", "2", "3", "4", "5", "6"];
    else if (joint == "respiratory") return ["A", "B", "C", "D"];
    return ["A", "B", "C", "D", "E"];
  }

  //TODO! crear type
  public static getProtocolLimitations(){
    return ["B", "C", "D"];
  }

  public static getCameraInitialPositions(jointCategory: JointCategory) {
    if (jointCategory != "musculoskeletal") return [];
    else return [
      "kneeling_down_lat_half_supp_knee",
      "kneeling_down_lat_straight_trunk",
      "kneeling_general_lat_relax_trunk",
      "monopodal_down_front_flx_kneedown",
      "monopodal_down_lat_ext_knee",
      "prone_general_lat_ext_trunk",
      "prone_general_lat_ext_trunk_plank",
      "quadrupedia_general_lat",
      "quadrupedia_up_front",
      "quadrupedia_up_lat_ext_forward_arm",
      "sidelying_down_front_ext_legs",
      "sidelying_face_front_lat_head",
      "sidelying_up_front_flx_up_arm",
      "sidelying_up_front_relax_arm",
      "sitting_down_front_pargeneralel_legs",
      "sitting_down_lat_ext_leg",
      "sitting_down_lat_pargeneralel_legs",
      "sitting_face_front_neutral_neck",
      "sitting_general_lat_forward_foot",
      "sitting_up_front_relax_arms",
      "sitting_up_lat_ext_forward_arm",
      "standing_down_front_open_legs",
      "standing_down_front_supp_legs",
      "standing_down_lat_flx_leg",
      "standing_down_lat_supp_legs",
      "standing_general_front_relax_posture",
      "standing_general_lat_forward_foot",
      "standing_up_front_bent_arms",
      "standing_up_front_half_open_arms",
      "standing_up_front_open_arms",
      "standing_up_front_relax_arms_90",
      "standing_up_front_relax_arms_180",
      "standing_up_lat_bent_arm_back",
      "standing_up_lat_bent_arms_forward",
      "standing_up_lat_bent_elbows_supp",
      "standing_up_lat_right_ext_arm",
      "standing_up_lat_left_ext_arm",
      "standing_up_lat_ext_arm_supp",
      "standing_up_lat_ext_forward_arm",
      "standing_up_lat_leaning_trunk_arms_down",
      "standing_up_lat_leaning_trunk_arms_up",
      "standing_up_lat_leaning_trunk_relax_arm",
      "supine_down_lat_ext_knee",
      "supine_down_lat_ext_leg",
      "supine_down_lat_flx_up_leg",
      "supine_general_lat_flx_arms_legs",
      "supine_general_lat_relax_trunk",
      "supine_up_lat_flx_forward_arm",
      "supine_up_lat_relax_arms",
    ];
  }

  public static getLimitationTranslationPrefix(jointCategory: JointCategory){
    if(jointCategory == "logopedics") return "limitation_severity";
    return "limitation";
  }

  public static getOutputStages(){
    let allStages = this.getAllStages();
    allStages.splice(allStages.indexOf("wip"), 1);
    return allStages;
  }

  // TODO: Cambiar type en todos los sitios donde se llama
  public static getCategories(): JointCategory[]{
    return ["musculoskeletal","logopedics","respiratory","pelvicFloor"];
  }

  public static getSortKey(joint: RehubJoint, version: string | null = null) {
    let sortKey = joint.category + "_" + joint.part + "_" + joint.slug;
    return version ? version + "_" + sortKey : sortKey;
  }

  public static getColor(key: string, variant: string = "main"){

    if(!key) key = "NoKey";

    let colors: any = {
      "Pitch": {main: "#37b67a", transparent: "#37b67a50"},
      "Roll": {main: "#00ADC4", transparent: "#00ADC450"},
      "Yaw": {main: "#ED8945", transparent: "#ED894550"},
      "Goniometer": {main: "#A46AFF", transparent: "#A46AFF50"},
      "Strength": {main: "#31393C", transparent: "#31393C50"},
      "Reference": {main: "#CCCCCC", transparent: "#CCCCCC50"},

      "Error": {main: "#F16F8D", transparent: "#F16F8D50"},
      "Primary": {main: "#2296F3", transparent: "#2296F350"},

      "NoKey": {main: "#000000", transparent: "#00000050"},
      "max": {main: "#930198", transparent: "#00000050"},
      "min": {main: "#033F5C", transparent: "#00000050"},
    }

    return colors[key][variant];
  }

  public static getValidatorsBySignal(Validators: any, signal: string, minOrMaxDegree: string, signalShape: string, isMainSignal: boolean, margin: number = 5) {
    let validators = [Validators.required];
    let isMin = minOrMaxDegree == "min";
    let isMax = minOrMaxDegree == "max";

    if (signal == "Strength") {
      if (isMin) validators.push(Validators.min(0), Validators.max(margin));
      if (isMax) validators.push(Validators.min(0), Validators.max(50));
    } else if (!isMainSignal) {
      validators.push(Validators.min(-180), Validators.max(360));
    } else if (signalShape == "bidirectional") {
      validators.push(Validators.min(-180), Validators.max(360));
      if (isMin) validators.push(Validators.min(-180), Validators.max(-this.BIDRECTIONAL_MARGIN ));
      if (isMax) validators.push(Validators.min(this.BIDRECTIONAL_MARGIN ), Validators.max(360));
    } else if (signalShape == "mountain") {
      if (isMin) validators.push(Validators.min(-margin), Validators.max(margin));
      if (isMax) validators.push(Validators.min(margin), Validators.max(360));
    } else if (signalShape == "valley") {
      if (isMin) validators.push(Validators.min(-360), Validators.max(margin));
      if (isMax) validators.push(Validators.min(-margin), Validators.max(margin));
    }

    return validators;
  }

  public static getRangeValidatorsBySignal(signal: string, minOrMaxDegree: string, signalShape: string, isMainSignal: boolean, margin: number = 5) {
    let isMin = minOrMaxDegree == "min";
    let isMax = minOrMaxDegree == "max";


    if (signal == "Strength") {
      if (isMin) return { min: 0, max: margin };
      if (isMax) return { min: 0, max: 50 };
    } else if (!isMainSignal) {
      return { min: -180, max: 360 };
    } else if (signalShape == "bidirectional") {
      // validators.push(Validators.min(-180), Validators.max(360));
      if (isMin) return { min: -180, max: -this.BIDRECTIONAL_MARGIN  };
      if (isMax) return { min: this.BIDRECTIONAL_MARGIN , max: 360 };
    } else if (signalShape == "mountain") {
      if (isMin) return { min: -margin, max: margin };
      if (isMax) return { min: margin, max: 360 };
    } else if (signalShape == "valley") {
      if (isMin) return { min: -360, max: margin };
      if (isMax) return { min: -margin, max: margin };
    }
  }

  public static getProtocolWithoutSide(slug: string){
    return slug.replace("_left", "").replace("_right", "");
  }

  public static getSideOfProtocolSlug(slug: string){
    if(slug.includes("left")) return "left";
    else if(slug.includes("right")) return "right";
  }

  // TODO: Añadir tests unitarios
  public static getROM(min: number, max: number){
    return Math.round(Math.abs(max - min) * 100) / 100;
  }

  // TODO: Añadir tests unitarios
  public static getSpeedFromIterationTime(rom: number, iterationTime: number) {
    let result = (rom * 2) / iterationTime;
    return Number.isNaN(result) ? 0 : result;
  }

  // TODO: Añadir tests unitarios
  public static getIterationTimeFromSpeed(rom: number, speed: number) {
    let result = (rom * 2) / speed;
    return Number.isNaN(result) ? 0 : parseFloat(result.toFixed(2));
  }

  public static getContralateralSensorPosition(sensorPosition: SensorPosition): SensorPosition {
    if (this.isSensorPositionXPositive(sensorPosition)) return "x_n";
    else if (this.isSensorPositionXNegative(sensorPosition)) return "x_p";
    else return sensorPosition;
  }

  public static getContralateralSignalShape(signalShape: SignalShape): SignalShape {
    if (signalShape == "mountain") return "valley";
    else if (signalShape == "valley") return "mountain";
    else return signalShape;
  }

  public static getContralateralSignalDegrees(signalDegrees: SignalDegrees): SignalDegrees {
    let result: SignalDegrees = {
      minDegree: -signalDegrees.maxDegree,
      maxDegree: -signalDegrees.minDegree
    };

    if (signalDegrees.minViewDegree && signalDegrees.maxViewDegree) {
      result.minViewDegree = -signalDegrees.maxViewDegree;
      result.maxViewDegree = -signalDegrees.minViewDegree;
    }

    return result;
  }

  public static getContralateralProtocol(protocol: RehubProtocol): RehubProtocol {
    let result: RehubProtocol = SharedUtils.deepCopy(protocol);
    let signalDegreesChanged: boolean = false;
    let mainSignalChanged: boolean = false;

    result.contralateral = true;
    result.contralateralVidProtocolId = protocol.videoProtocol || protocol.slug;
    result.contralateralThumbProtId = protocol.thumbnailProtocol || protocol.slug;
    result.joint = ProtocolUtils.getOppositeSideFromProtocolSlug(protocol.joint!);

    if(protocol.sensorEnabled && this.isSensorPositionContralateral(protocol.sensorPosition!)){
      let signalsToUpdate = this.getContralateralSignalsToUpdate(protocol.degrees!);

      signalsToUpdate.forEach((signal: SignalName)=>{
        result.degrees![signal] = this.getContralateralSignalDegrees(protocol.degrees![signal]!);

        if (protocol.mainSignal == signal) mainSignalChanged = true;
      });

      result.sensorPosition = this.getContralateralSensorPosition(protocol.sensorPosition!);
    }

    if (protocol.cameraEnabled) {

      if (protocol.sensorEnabled && !mainSignalChanged) {
        result.cam_mainMovement!.inverted = !Boolean(protocol.cam_mainMovement!.inverted);
      }

      if (!protocol.sensorEnabled) {
        result.degrees![protocol.mainSignal!] = this.getContralateralSignalDegrees(protocol.degrees![protocol.mainSignal!]!);
        mainSignalChanged = true;
      }

      let contralaterals:any;
      if(protocol.cam_model=="HAND_MODEL") contralaterals= Object.values(CameraHandPointsContralateral);
      else contralaterals= Object.values(CameraPointsContralateral);

      result.cam_mainMovement!.initial = contralaterals[protocol.cam_mainMovement!.initial];
      result.cam_mainMovement!.final = contralaterals[protocol.cam_mainMovement!.final];

      result.cam_mainMovement!.draw_initial = contralaterals[protocol.cam_mainMovement!.draw_initial];
      result.cam_mainMovement!.draw_final = contralaterals[protocol.cam_mainMovement!.draw_final];

      let degrees = result.degrees![protocol.mainSignal!]!;
      result.cam_mainMovement!.min = degrees.minDegree;
      result.cam_mainMovement!.max = degrees.maxDegree;

      (result.cam_secondaryMovements || []).forEach((secondaryMovement: CameraTrackingSecondaryMovement, index: number)=>{
        secondaryMovement!.initial = contralaterals[protocol.cam_secondaryMovements![index]!.initial];
        secondaryMovement!.final = contralaterals[protocol.cam_secondaryMovements![index]!.final];

        let minTemp = secondaryMovement!.min;
        secondaryMovement!.min = -secondaryMovement!.max;
        secondaryMovement!.max = -minTemp;
      });

      (result.cam_offsets|| []).forEach((compensation: CameraTrackingCompensation, index: number)=>{
        compensation!.initial = contralaterals[protocol.cam_offsets![index]!.initial];
        compensation!.final = contralaterals[protocol.cam_offsets![index]!.final];
      });
    }

    if (mainSignalChanged) result.signalShape = this.getContralateralSignalShape(protocol.signalShape!);

    return result;
  }

  public static getThumbnail(record: any, protocolData: any, environment: any, size: "medium" | "small", version: string) {
    if (record && record.thumbnailOutputKey) {
      return environment.output_s3_url + record.thumbnailOutputKey + "-" + size + ".jpg";
    } else if (record && record.thumbnailUrl) {
      return record.thumbnailUrl;
    } else if (protocolData && protocolData.thumbnailOutputKey) {
      return environment.output_s3_url + protocolData.thumbnailOutputKey + "-" + size + ".jpg";
    } else if (protocolData && protocolData.thumbnailUrl) {
      return protocolData.thumbnailUrl;
    } else if(protocolData){
      return ProtocolUtils.getThumbnailFromSlug(protocolData.thumbnailProtocol || protocolData.slug, size, environment, version);
    }
  }

  public static getVideoProtocol(videoProtocol: string, protocolId: any, recordId: any, editingProtocol: boolean) {
    if (videoProtocol) {
      if (videoProtocol.startsWith("CUSTOM_")) {
        return TherapyAPI.getVideoProtocol(videoProtocol);
      } else {
        return Promise.resolve(null);
      }
    } else if (editingProtocol) {
      return TherapyAPI.getVideoProtocol(protocolId);
    } else {
      return TherapyAPI.getVideoRecord(recordId);
    }
  }

  public static getVideo(requestVideo: any, environment: any, videoName: any, version: string) {
    if (this.hasVideo(requestVideo)) {
      if (requestVideo.outputFilePath) {
        return environment.output_s3_url + requestVideo.outputFilePath;
      } else if (requestVideo.videoKey) {
        return requestVideo.videoKey;
      } else if (requestVideo.videoUrl) {
        return requestVideo.videoUrl;
      } else {
        return requestVideo;
      }
    } else {
      return ProtocolUtils.getVideoFromSlug(videoName, environment, version);
    }
  }

  public static getPrepVideo(requestVideo: any, environment: any, videoName: any, version: string) {
    if (this.hasPrepVideo(requestVideo)) {
      if (requestVideo.outputFilePathPrep) {
        return environment.output_s3_url + requestVideo.outputFilePathPrep;
        // TODO: Eliminar videoUrlPrep
      }else if (requestVideo.videoUrlPrep) {
        return requestVideo.videoUrlPrep;
      } else {
        return requestVideo;
      }
    } else {
      return ProtocolUtils.getPrepVideoFromSlug(videoName, environment, version);
    }
  }

  public static hasPrepVideo(prepVideoResult: any) {
    if (!prepVideoResult) return false;

    let keys = ["outputFilePathPrep", "prepVideoUrl"];

    return Object.keys(prepVideoResult).some((key: string) => {
      return keys.includes(key);
    });
  }

  public static hasVideo(videoResult: any) {
    if (!videoResult) return false;

    let keys = ["outputFilePath", "videoUrl"];

    return Object.keys(videoResult).some((key: string) => {
      return keys.includes(key);
    });
  }

  public static getThumbnailFromSlug(slug: string, size: "small" | "medium" = "medium", environment: any, version: string) {
    if(ProtocolUtils.isCustomFromSlug(slug)) return SharedUtils.getRehubAsset("exercises/images-" + size + "/custom.jpg", environment, version);
    else return SharedUtils.getEditorAsset("protocols/thumbnails/" + size + "/" + slug + ".jpg", environment, version);
  }

  public static getVideoFromSlug(slug: string, environment: any, version: string): string {
    if(ProtocolUtils.isCustomFromSlug(slug)) return SharedUtils.getRehubAsset("exercises/videos/protocol/CUSTOM.mp4", environment, version);
    else return SharedUtils.getEditorAsset("protocols/videos/" + slug + ".mp4", environment, version);
  }

  public static getVideoFromSlugWithDegrees(slug: string, environment: any, version: string, degrees:string): string {
    if(ProtocolUtils.isCustomFromSlug(slug)) return SharedUtils.getRehubAsset("exercises/videos/protocol/CUSTOM.mp4", environment, version);
    else return SharedUtils.getEditorAsset("protocols/romVideos/rom_"+ degrees +"_"+ slug + ".mp4", environment, version);
  }

  public static getPrepVideoFromSlug(slug: string, environment: any, version: string): string {
    if(ProtocolUtils.isCustomFromSlug(slug)) return SharedUtils.getRehubAsset("exercises/videos/protocol/CUSTOM.mp4", environment, version);
    else return SharedUtils.getEditorAsset("protocols/prepVideos/prep_" + slug + ".mp4", environment, version);
  }

  public static getVideoFromSensorInstructions(sensorInstructions: string, environment: any, version: string){
    if(sensorInstructions == null || sensorInstructions == "") sensorInstructions = "default";
    return SharedUtils.getRehubAsset("exercises/videos/sensorInstructions/" + sensorInstructions + ".mp4", environment, version);
  }

  /**
   * @param slug Articulación del protocolo
   * @returns true si el protocolo es custom (ha sido creado por un profesional)
   */
  public static isCustomFromSlug(slug: string) {
    if(slug == null) return false;
    return slug.startsWith("CUSTOM");
  }

  //TODO! REFACTOR Adaptar para que se peuda usar en ReHub también a parte del editor
  public static filterProtocols(protocols: RehubProtocol[], filter: any, i18nItems: any) {
    let keys = Object.keys(filter);
    return protocols.filter((protocol: any) => {
      if (keys.length == 0) return true;

      // Se busca un parámetero que no concuerde con el filtro para no mostrarlo
      let notMatchingField = keys.find((key: any) => {

        let filterValue = SharedUtils.toArrayIfNotArray(filter[key]);

        //Si no hay filtro todos los protocolos son válidos
        if (filterValue.length == 0) return false;

        if (key == "name") {
          let i18n = i18nItems && i18nItems[protocol.protocolName] ? i18nItems[protocol.protocolName].text_es : protocol.protocolName;
          let strippedProtocolName = SharedUtils.stripAccents(i18n.toLowerCase());
          let strippedFilter = SharedUtils.stripAccents(filterValue[0].toLowerCase());
          return !strippedProtocolName.includes(strippedFilter) && !protocol.slug.toLowerCase().includes(strippedFilter);
        } else if (key == "stages") {
          let value = filterValue[0]; // Solamente hay un valor
          // Si la clave es all se devuelven los que no tengan stages (los que están en prod)
          if (value == "all") return protocol.stages != null;
          else return !protocol.stages || !protocol.stages.includes(value);
        }

        else if (key == "jointParts") {
          return null == filterValue.find((value: string) => protocol.jointPart && protocol.jointPart == value);
        } else if (key == "joints") {
          return null == filterValue.find((value: string) => protocol.joint && protocol.joint.includes(value));
        } else if (key == "sides") {
          return null == filterValue.find((value: string) => protocol.side && protocol.side == value);
        } else if (key == "goals") {
          return null == filterValue.find((value: string) => protocol.goal && protocol.goal.includes(value));
        } else if (key == "sensorKitTypes") {
          return null == filterValue.find((value: string) => {
            if (!protocol.sensorKitType) return false;
            else if (protocol.sensorKitType.includes(value)) return value == 'CAMERA' ? true : protocol.sensorEnabled;
          });
        } else if (key == "movements") {
          return null == filterValue.find((value: string) => protocol.movements && protocol.movements.includes(value));
        } else if (key == "positions") {
          return null == filterValue.find((value: string) => protocol.positions && protocol.positions.includes(value));
        } else if (key == "tools") {
          return null == filterValue.find((value: string) => protocol.tools && protocol.tools.includes(value));
        } else if (key == "exerciseTypes") {
          return null == filterValue.find((value: string) => protocol.exerciseTypes && protocol.exerciseTypes.includes(value));
        } else if (key == "category") {
          return null == filterValue.find((value: string) => protocol.sortKey && protocol.sortKey.includes(value));
        } else if (key == "version") {
          return null == filterValue.find((value: string) => protocol.sortKey && protocol.sortKey.startsWith(value));
        }else if (key == "limitations"){
          return null == filterValue.find((value: string)=>protocol.limitation == value);
        }else if (key == "muscleGroups"){
          return null == filterValue.find((value: string)=>protocol.muscleGroups && protocol.muscleGroups.includes(value));
        }

        return false;
      });

      // Si coinciden todos los campos se muestra
      return !notMatchingField;
    });
  }

  public static sortProtocolsByName(protocols: RehubProtocol[], i18nItems: any) {
    return protocols.sort((a: RehubProtocol, b: RehubProtocol) => {
      let aI18n = a.protocolName && i18nItems[a.protocolName] && i18nItems[a.protocolName].text_es ? i18nItems[a.protocolName].text_es : a.protocolName;
      let bI18n = b.protocolName && i18nItems[b.protocolName] && i18nItems[b.protocolName].text_es ? i18nItems[b.protocolName].text_es : b.protocolName;
      return aI18n.localeCompare(bI18n);
    });
  }

  public static getSensorKitType(protocol: any, sensorTypeToSensorKit: any) {
    let result = [];

    if (protocol.sensorType) {
      result.push(...sensorTypeToSensorKit[protocol.sensorType]);
    }

    if (protocol.cameraEnabled) result.push("CAMERA");

    if (!protocol.sensorType && !protocol.sensorEnabled) {
      result.push("NO_SENSOR");
    }

    return result;
  }

  public static formatExercisesByDate(result: any, moment: any, environment: any, deployInfoService: SharedDeployInfoService, i18nService: SharedI18nService){
    if(result && result.exercises){
      result.exercises = result.exercises.filter((e: any)=>result.protocolData[e.protocol] != null)
      .map((e: any)=>{
        let protocol = result.protocolData[e.protocol];
        let seconds = ExerciseUtils.getRemainingSecondsFromExercise(e, true);

        return {
          ...e,
          jointTranslation: this.getJointTranslation(i18nService, e.joint),
          protocolTranslation: this.getProtocolTranslation(i18nService, protocol),
          // protocolTranslationWithJoint: this.getProtocolTranslation(i18nService, protocol, true),
          imageURL: ProtocolUtils.getThumbnail(e, protocol, environment, "medium", deployInfoService.getURLCacheParam()),
          imageSmallURL: ProtocolUtils.getThumbnail(e, protocol, environment, "small", deployInfoService.getURLCacheParam()),
          sensor: protocol.sensorEnabled ? SharedUtils.getSensorTypeIcon(protocol.sensorType) : null,
          tools: this.getToolsFormatted(protocol.tools, environment),
          duration: seconds <= 0 ? "-" : moment.utc(moment.duration(seconds, "s").asMilliseconds()).format("mm:ss")
        }
      });
    }

    return result;
  }

  public static getToolsFormatted(tools: string[], environment: any){
    return tools.map((tool: any) => {
      return {
        url: environment.professional_url + "/assets/images/tools/" + tool + ".png",
        name: tool
      };
    });
  }

  public static getDefaultProtocolThumbnailURL(environment: any){
    return environment.assets_url + "/rehub-assets/exercises/images-medium/custom.jpg";
  }

  public static getDefaultProtocolVideoURL(environment: any){
    return environment.assets_url + "/rehub-assets/exercises/videos/protocol/CUSTOM.mp4";
  }

  public static areProtocolsParamsEqual(protocols: any, params: string[]){

    // Si no hay protocolos o si solo hay uno, no hay nada que comparar, todos los protocolos son iguales
    if(protocols.length <= 1) return true;

    let firstProtocol = protocols[0].params || {};

    let found = false;
    let i = 1;

    // Se hace una búsqueda en todos los protocolos menos el primero
    while(found == false && i < protocols.length){
      // Se compara el primer protocolo con todos los demás
      let protocol = protocols[i].params || {};
      let j = 0;

      // Se hace una búsqueda en todos los parámetros indicados en params hasta que aparece uno que no sea igual
      while(found == false && j < params.length){
        let param = params[j];

        let firstProtocolParam = firstProtocol[param];
        let protocolParam = protocol[param];

        // Si el parámetro del protocolo actual es diferente al del primer protocolo se para de buscar.
        // Si el parámetro del protocolo actual es un objeto se tiene que comprobar la igualdad de todos los parámetros de dentro
        if(firstProtocolParam instanceof Object) found = !SharedUtils.objectEquals(firstProtocolParam, protocolParam);
        else found = firstProtocolParam != protocolParam;
        j++;
      }
      i++;
    }

    return !found;
  }

  /**
   * @param initial El punto inicial del segmento
   * @param final El punto final del segmento
   * @returns El id del segmento de cámara que contiene a los puntos indicados
   */
  public static getCameraSegmentFromCameraPoints(initial: number, final: number){
    let keys = Object.keys(CameraSegments);
    return keys.find((segmentKey: string)=>{
      let segment = CameraSegments[segmentKey];
      return segment.includes(initial) && segment.includes(final);
    });
  }

  /**
   * @param protocol El protocolo del que se quiere obtener el id de las instrucciones de cámara
   * @returns El id de las instrucciones de cámara del protocolo a partir de cam_plane
   */
  public static getCameraInstructionsIdFromProtocol(protocol: any){
    if(!protocol.cam_plane) return null;

    if(protocol.cam_plane.includes("up")) return "camera_upper";
    else if(protocol.cam_plane.includes("down")) return "camera_lower";
    else if(protocol.cam_plane.includes("general")) return "camera_general";
  }

  public static getSideFromProtocolSlug(protocol: string){
    return protocol.includes("left") ? "left" : protocol.includes("right") ? "right" : "";
  }

  public static hasSidesFromProtocolSlug(protocol: string){
    return protocol.includes("left") || protocol.includes("right");
  }

  public static getOppositeSideFromProtocolSlug(protocol: string) {
    return protocol.includes("left") ? protocol.replace("left", "right") : protocol.includes("right") ? protocol.replace("right", "left") : "";
  }

  public static getJointFromJointsFull(joint: string, jointsFull: any[]): RehubJoint{
    return jointsFull.find((jointFull: any)=> jointFull.slug == joint);
  }

  // TODO! Añadir tests
  public static getExtremityFromCameraPlane(camPlane: CamPlane): CamPlaneExtremity{
    let array = camPlane ? camPlane.split("_") : ["", ""];
    return array[0] as CamPlaneExtremity;
  }

  // TODO! Añadir tests
  public static getPlaneFromCameraPlane(camPlane: CamPlane): CamPlanePlane{
    let array = camPlane ? camPlane.split("_") : ["", ""];
    return array[1] as CamPlanePlane;
  }

  // TODO! Añadir tests
  public static getSignalShapeFromMinAndMax(min: number, max: number): SignalShape | undefined{
    //TODO ahora mismo no funciona bien
    if (min >= -5 && min <= 5 && max >= 5 && max <= 360) return "mountain";
    else if (min >= -360 && min <= 5 && max >= -5 && max <= 5) return "valley";
    else if (min >= -180 && min <= -5 && max >= 5 && max <= 360) return "bidirectional";
  }

  /**
   * Devuelve un array con todos los puntos de cámara del protocolo
   * @param protocol El protocolo del que se quiere obtener los puntos de cámara
   * @returns Un array con todos los puntos de cámara del protocolo
   */
  public static getAllCameraPointsFromProtocol(protocol: RehubProtocol): number[]{
    if(!protocol || !protocol.cameraEnabled) return [];

    let points: number[] = [];

    points.push(protocol.cam_mainMovement!.initial, protocol.cam_mainMovement!.final);

    (protocol.cam_secondaryMovements || []).forEach((movement: CameraTrackingSecondaryMovement)=>{
      points.push(movement.initial, movement.final);
    });

    (protocol.cam_offsets || []).forEach((movement: CameraTrackingCompensation)=>{
      points.push(movement.initial, movement.final);
    });

    return points;
  }

  /**
   * @param points Los puntos de cámara del protocolo
   * @returns "up", "down" o "general" dependiendo de los puntos de cámara del protocolo y su posición en el cuerpo
   */
  public static getCamPlaneExtremityFromProtocolPoints(points: number[], model: string): CamPlaneExtremity{

    let planes = new Set<CamPlaneExtremity>();

    let i = 0;

    while(planes.size < 2 && i < points.length){
      if(CameraPointsByCamPlane.up.includes(points[i])) planes.add("up");
      if(CameraPointsByCamPlane.down.includes(points[i])) planes.add("down");
      i++;
    }

    if(planes.size == 2) return "general";
    if(model == "HAND_MODEL") return "general";

    else return planes.values().next().value;
  }

  // public static getCameraSegments(): string[]{
  //   return Object.keys(CameraSegments);
  // }

  public static getCameraLandmarks(){
   return   {
      BODY_MODEL:Object.values(CameraPoints).filter((value: number)=>![1, 2, 3, 4].includes(value)),
      HAND_MODEL: Object.values(CameraHandPoints)
     }
  }

  public static getCameraCalcModes(): CalcMode[]{
    return ["frontal", "sagittal", "transverse"]; //["frontal", "sagittal", "transverse", "strength"];
  }

  private static isSensorPositionContralateral(sensorPosition: SensorPosition) {
    return this.isSensorPositionXContralateral(sensorPosition) || this.isSensorPositionYContralateral(sensorPosition) || this.isSensorPositionZContralateral(sensorPosition);
  }

  private static isSensorPositionXContralateral(sensorPosition: SensorPosition) {
    return this.isSensorPositionXPositive(sensorPosition) || this.isSensorPositionXNegative(sensorPosition);
  }

  private static isSensorPositionXPositive(sensorPosition: SensorPosition) {
    return sensorPosition == "x_p";
  }

  private static isSensorPositionXNegative(sensorPosition: SensorPosition) {
    return sensorPosition == "x_n";
  }

  private static isSensorPositionYContralateral(sensorPosition: SensorPosition) {
    return sensorPosition == "y_p" || sensorPosition == "y_n";
  }

  private static isSensorPositionZContralateral(sensorPosition: SensorPosition) {
    return sensorPosition == "z_p" || sensorPosition == "z_n";
  }

  private static getContralateralSignalsToUpdate(degrees: Degrees): SignalName[] {
    let signalsToChange: SignalName[] = ["Yaw", "Roll"];
    return signalsToChange.filter((signal: SignalName)=>Object.keys(degrees).includes(signal));
  }
}