<!-- <div class="d-flex flex-column bg-white h-100" style="height: -webkit-fill-available !important;">
  <div class="bg-danger w-100">
    header
  </div>

  <div class="bg-primary w-100 overflow-auto" style="flex: auto;">
    <div class="w-100 bg-white mb-2" style="height: 100px;"></div>
    <div class="w-100 bg-white mb-2" style="height: 100px;"></div>
    <div class="w-100 bg-white mb-2" style="height: 100px;"></div>
    <div class="w-100 bg-white mb-2" style="height: 100px;"></div>
    <div class="w-100 bg-white mb-2" style="height: 100px;"></div>
    <div class="w-100 bg-white mb-2" style="height: 100px;"></div>
    <div class="w-100 bg-white mb-2" style="height: 100px;"></div>
    <div class="w-100 bg-white mb-2" style="height: 100px;"></div>
    <div class="w-100 bg-white mb-2" style="height: 100px;"></div>
    <div class="w-100 bg-white mb-2" style="height: 100px;"></div>
    <div class="w-100 bg-white mb-2" style="height: 100px;"></div>
    <div class="w-100 bg-white mb-2" style="height: 100px;"></div>
    <div class="w-100 bg-white mb-2" style="height: 100px;"></div>
    <div class="w-100 bg-white mb-2" style="height: 100px;"></div>
    <div class="w-100 bg-white mb-2" style="height: 100px;"></div>
    <div class="w-100 bg-white mb-2" style="height: 100px;"></div>
    <div class="w-100 bg-white mb-2" style="height: 100px;"></div>

  </div>

  <div class="bg-danger w-100">
    footer
  </div>
</div> -->

<div class="d-flex flex-row w-100 h-100" style="height: -webkit-fill-available !important;" *ngIf="!isLoading">

  <div class="d-none d-lg-block w-25 h-100">
    <rehub-bar-selector class="h-100 pl-0 pr-0" [isSearch]="false" [items]="items" (itemSelected)="changeUser($event)">
      <ng-template let-item="item">
        <div class="row align-items-center border-0 pt-3 pb-3 mr-0 ml-0 bg-primary" *ngIf="item.type == 'group'">
          <div class="col">
            <h4 class="m-0 text-white">{{item.group.name}}</h4>
          </div>
        </div>
        <div class="row align-items-center border-bottom border-top bg-white pt-3 pb-3 mr-0 ml-0 user"
          [ngClass]="{selected: item.user.member.selected, pointer: !item.user.member.selected}"
          *ngIf="item.type == 'user'">
          <div class="col-3 pr-0 h-100">
            <div class="text-center h-100 d-flex flex-row align-items-center justify-content-center">
              <rehub-avatar [image]="getAvatar(item)" [name]="item.user.member.alias"></rehub-avatar>
            </div>
          </div>
          <div class="col">
            <h3 class="m-0">{{item.user.member.name}}</h3>
          </div>
        </div>
      </ng-template>
    </rehub-bar-selector>
  </div>

  <div class="d-flex flex-column bg-white h-100" style="flex: auto; height: -webkit-fill-available !important;">

    <!-- HEADER -->
    <div class="rehub-background w-100 px-2 d-block d-lg-none">
      <select class="custom-select custom-select-lg mt-2 mb-2" [ngModel]="selectedUser"
        (ngModelChange)='onUserSelected($event)'>
        <ng-container *ngFor="let item of items">
          <optgroup *ngIf="item.type == 'group'" label="{{item.group.name}}"></optgroup>
          <option *ngIf="item.type == 'user'" value="{{item.user.member.userId}}">
            {{item.user.member.name}}</option>
        </ng-container>
      </select>
    </div>

    <!-- CONTENT -->
    <div class="w-100 overflow-auto" style="flex: auto;">
      <div class="row flex-fill ml-0 mr-0 h-100 overflow-auto" style="flex: auto;">

        <div class="col overflow-auto flex-shrink-1 pl-0 pr-0" *ngIf="!isMessage">
          <div class="position-absolute h-100 w-100">
            <div class="row d-flex justify-content-center h-100 no-gutters">
              <img src="/assets/images/noChat.svg" />
              <h3 class="col-10 col-sm-10 col-md-10 col-lg-8 col-xl-10 ml-auto mr-auto mt-2 text-center "  >
                {{ (isSelfService() ? 'chat_ask_questions_self_service' : 'chat_ask_questions')| translate }}</h3>
            </div>
          </div>
        </div>

        <div class="col overflow-auto flex-shrink-1 position-relative pl-0 pr-0 h-100" id="messagesContainer" *ngIf="isMessage" >
          <div class="h-100 w-100">
            <ng-container *ngFor="let message of selectedChatGroup.messages">
              <div class="row pt-2 pb-2 ml-1 mr-1" [ngClass]="{'justify-content-end': message.sender == 'me'}">
                <div style="max-width: 500px" class="mx-3">
                  <div class="p-3 mb-1 rounded w-100"
                    [ngClass]="{'bg-light speech-bubble-right': message.sender == 'me', 'bg-gray-6 speech-bubble-left': message.sender == 'other'}">

                    <p class="m-0 w-100">
                      <ng-container *ngIf="!message.removedDate">

                        <ng-container *ngIf="message.fileUrl != null">
                          <div class="d-flex flex-row align-items-center justify-content-center w-100">
                            <rehub-icon name="file" class="mr-2 icon-file"></rehub-icon>

                            <div class="d-flex flex-column text-file mr-2">
                              <span class="name-file" style="max-width: 20ch;" [attr.title]="getFileNameFromMessage(message.note)">{{ getFileNameFromMessage(message.note) }}</span>
                              <span class="text-gray-3">{{ getFileSizeFromMessage(message.note) }}</span>
                            </div>

                            <rehub-icon name="download" class="cursor-pointer icon-file" (click)="onDownloadFilePressed(message.fileUrl)"></rehub-icon>
                          </div>
                        </ng-container>

                        <ng-container *ngIf="message.fileUrl == null">
                          <span style="white-space: pre-line; word-break: break-word;">{{message.note}}</span>
                        </ng-container>
                      </ng-container>
                      <ng-container *ngIf="message.removedDate">{{ 'chat_message_removed' | translate }}
                      </ng-container>
                    </p>

                    <div class="d-flex flex-row mt-2" *ngIf="message.isCall">
                      <rehub-icon name="check_circle" class="cursor-pointer text-green-3 mr-2" size="48"
                        (click)="acceptIncomingCall(message.sortKey)"></rehub-icon>
                      <rehub-icon name="close_circle" class="cursor-pointer text-danger" size="48"
                        (click)="refuseIncommingCall(message.sortKey)"></rehub-icon>
                    </div>
                  </div>
                  <div [ngClass]="{'text-right': message.sender == 'me'}" class="date-chat mt-3">
                    {{message.createDate | date:'d/M/yyyy h:mm'}}
                  </div>
                  <div *ngIf="message.messageType && message.messageType == 'PATIENT_DAILY'" [ngClass]="{'text-right': message.sender == 'me'}">
                    <span class="patient-daily">{{ 'daily_form_chat_prefix' | translate }}</span>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <!-- FOOTER -->

    <div class="d-flex align-items-center justify-content-center my-2 py-1 sticky-bottom" *ngIf="isMessage && isSelfService()" >
      <div class="alert-warning rounded w-75 align-items-center mx-auto text-center" >
        <div class="time_self_service ">{{ 'alert_time_selfservice' | translate }}</div>
      </div>
    </div>


    <div class="rehub-background d-flex flex-row w-100 px-2 py-1" style="gap: 10px; border-top: 2px solid var(--color-gray-6);">
      <div class="w-100 position-relative">
        <input #newMessage type="text" class="inputText form-control bg-gray-6 border-0 h-100 p-3 rounded-pill pr-5" [disabled]="disableSend" placeholder="{{'chat_write_message' | translate}}" [(ngModel)]="message" (keyup.enter)="onSendMessagePressed()" />
        <rehub-icon *ngIf="permissionFileUpload" style="position: absolute; right: 10px; top: 12px;" name="attach" class="text-primary cursor-pointer" (click)="fileInput.click()" [ngClass]="{'text-gray-3': disableSend}"></rehub-icon>
      </div>

      <div class="bg-primary text-white p-3 rounded" [ngClass]="{'bg-grey': !isValidMessage(newMessage.value) || disableSend, 'cursor-pointer': isValidMessage(newMessage.value) && !disableSend}" (click)="onSendMessagePressed()">
        <rehub-icon name="message" size="25"></rehub-icon>
      </div>

      <input [hidden]="true" type="file" (change)="onFileChange($event)" accept="/*" #fileInput />
    </div>
  </div>
</div>