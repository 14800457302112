import { API } from "./api";

export class PatientAPI extends API {

  static getNotificationsPatient(patientId: string): Promise<any>{
    const url = `${this.getBaseUrl()}/patient/notifications/${patientId}`;
    return this.get(url, {}, {timeout: 20000});
  }

  static getPatientExerciseByDate(patientId: string, date: any, therapyId?: string, diagnosisId?: string): Promise<any> {
    const url = `${this.getBaseUrl()}/patient/exercise/byDate`;

    const data: any = {
      patientId: patientId,
      date: date
    };
    if(therapyId) data.therapyId = therapyId;
    if (diagnosisId) data.diagnosisId = diagnosisId;

    return this.post(url, data, {timeout: 20000});
  }

  static addFormResponse(formId: string, response: any, note: string | null = null): Promise<any>{
    const url = `${this.getBaseUrl()}/patient/formResponse/create`;
    const data = {
      formId: formId,
      response: response,
      note: note
    };

    return this.post(url, data, {timeout: 20000});
  }

  static getPatientInfoStatus(): Promise<any>{
    let url = `${this.getBaseUrl()}/patient/info/status`;
    return this.get(url, {}, {timeout: 20000});
  }

  static getPatientInfo(patientId: string): Promise<any>{
    const url = `${this.getBaseUrl()}/patient/info/${patientId}`;
    return this.get(url, {}, {timeout: 20000});
  }

  static getPatientTempParam(patientId: string, sortKeyPrefix: string): Promise<any>{
    const url = `${this.getBaseUrl()}/patient/temp/${patientId}/${sortKeyPrefix}`;
    return this.get(url, {});
  }

  static getPatientInfoProfessional(patientId: string): Promise<any>{
    const url = `${this.getBaseUrl()}/patient/info/${patientId}/professional`;
    return this.get(url, {});
  }

  static getPatientInfoProfessionalStatus(patientId: string): Promise<any>{
    const url = `${this.getBaseUrl()}/patient/info/professional/status/${patientId}`;
    return this.get(url, {}, {timeout: 20000});
  }

  static updateLoginFormResponse(data: any): Promise<any>{
    const url = `${this.getBaseUrl()}/patient/loginFormResponse/update`;
    return this.post(url, data);
  }

  static getPatientsStatus(): Promise<any>{
    const url = `${this.getBaseUrl()}/patient/all/status`;
    return this.get(url, {});
  }

  static updateParam(authenticationService: any, patientId: string, params: any, callback: any = null, callbackError: any = null): Promise<any>{
    const data = {params: params}
    const url = `${this.getBaseUrl()}/patient/${patientId}/update/param`;

    return this.post(url, data, {timeout: 20000}).then((result: any) => {
      authenticationService.getAndSaveLoginInfo().then(callback).catch(callbackError);
    }).catch((error: any) => {
      console.error(error);
    });
  }

  static createPatient(data: any): Promise<any>{
    const url = `${this.getBaseUrl()}/patient/create`;
    return this.post(url, data);
  }

  static updatePatient(data: any): Promise<any>{
    const url = `${this.getBaseUrl()}/patient/update`;
    return this.post(url, data, {timeout: 20000});
  }

  static patientValidate(provider: string, externalId: string): Promise<any>{
    const url = `${this.getBaseUrl()}/patient/validate`;
    return this.post(url, {provider, externalId});
  }

  static setPatientState(patientState: any, patientId: any, professionalId: any = null): Promise<any>{
    const url = `${this.getBaseUrl()}/patient/update/state`;
    const data = {
      patientId: patientId,
      professionalId: professionalId,
      patientState: patientState
    }

    return this.post(url, data);
  }

  static getJointSummary(): Promise<any>{
    const url = `${this.getBaseUrl()}/patient/all/jointSummary`;
    return this.get(url, {}, {timeout: 20000});
  }

  static getCommonPracticeTime(): Promise<any>{
    const url = `${this.getBaseUrl()}/patient/all/commonPracticeTime`;
    return this.get(url, {}, {timeout: 20000});
  }

  static getTherapyCommonPracticeTime(patientId: string, diagnosisId: string, params: any): Promise<any>{
    let url = `${this.getBaseUrl()}/patient/info/${patientId}/commonPracticeTime/${diagnosisId}`;
    return this.get(url, params, {timeout: 20000});
  }

  static getMonitoredPatients(): Promise<any>{
    const url = `${this.getBaseUrl()}/patient/all/monitored`;
    return this.get(url, {}, {timeout: 20000});
  }

  static getAssignedPatients(): Promise<any>{
    const url = `${this.getBaseUrl()}/patient/all/assigned`;
    return this.get(url, {}, {timeout: 20000});
  }

  static getInPainPatients(): Promise<any>{
    const url = `${this.getBaseUrl()}/patient/all/pain`;
    return this.get(url, {}, {timeout: 20000});
  }

  static addOwner(userIds: string[], patientId: string): Promise<any>{
    const url = `${this.getBaseUrl()}/patient/owner/add`;
    const data: any = { userIds, patientId };
    return this.post(url, data);
  }

  static removeOwner(username: string): Promise<any>{
    const url = `${this.getBaseUrl()}/patient/owner/remove`;
    const data = {
      username: username
    }
    return this.post(url, data);
  }

  static getPatientsWithExercisesForToday(): Promise<any>{
    const url = `${this.getBaseUrl()}/patient/all/practice-today`;
    return this.get(url, {}, {timeout: 20000});
  }

  static getAllPatients(patientStates: any = null): Promise<any>{
    let url = `${this.getBaseUrl()}/patient/all`;

    let params: any = {};
    if(patientStates && patientStates.length > 0) params.patientState = patientStates;

    return this.get(url, params, {timeout: 5000});
  }

  static getEvolution(patientId: string): Promise<any>{
    let url = `${this.getBaseUrl()}/patient/info/${patientId}/evolution`;
    return this.get(url, {});
  }

  static getPatientNotes(patientId: string): Promise<any>{
    const url = `${this.getBaseUrl()}/patient/${patientId}/notes`;
    return this.get(url, {});
  }

  static addPatientNote(patientId: string, note: string): Promise<any>{
    const url = `${this.getBaseUrl()}/patient/${patientId}/note/create`;
    const data = {
      note: note
    }
    return this.post(url, data);
  }

  static getMedicalRecord(patientId: string): Promise<any>{
    const url = `${this.getBaseUrl()}/patient/medicalRecord/${patientId}`;
    return this.get(url, {});
  }

  static createMedicalRecord(patientId: string, data: any): Promise<any>{
    const url = `${this.getBaseUrl()}/patient/medicalRecord/${patientId}/create`;
    return this.post( url, data);
  }

  static updateMedicalRecord(patientId: string, data: any): Promise<any>{
    const url = `${this.getBaseUrl()}/patient/medicalRecord/${patientId}/update`;
    return this.post(url, data);
  }

  static getSessions(ownerType: string, ownerId: string, dateFilter: any = null): Promise<any> {
    const url = `${this.getBaseUrl()}/patient/session/${ownerType}/${ownerId}/all`;
    return this.get(url, { dateFilter });
  }

  static createSelfServiceProgram(data: any): Promise<any>{
    const url = `${this.getBaseUrl()}/patient/selfservice`;
    return this.post(url, data).catch((callbackError: any = null) => {
      return alert("Wait at least 30 seconds before creating the next program");
    });
  }
}