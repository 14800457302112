export type RehubEditorProtocolGroup = {
  groupId?: string,
  itemId?: string,
  sortKey?: string,

  groupName?: string,
  i18nGroupName?: string,
  owner?: {alias?: string, name?: string, responsibleId?: string},
  ownerId?:string;
  creatorId?: string,

  params?: {
    dayDuration?: number,
    during?: {
      Friday?: number,
      Monday?: number,
      Saturday?: number,
      Sunday?: number,
      Thursday?: number,
      Tuesday?: number,
      Wednesday?: number
    },
    iterationTime?: number,
    iterations?: number,
    maxIterations?: number,
    setsCount?: number,
    waitSeconds?: number
  },

  protocolIds?: string[],
  protocols?: any[],
  joint?: string,
  side?: string,
  stages?: string[],

  updateUserId?: string,
  updateDate?: string,
  updater?: {alias?: string, avatarOutputKey?: string, name?: string, userId?: string}
}

export class ProtocolGroupUtils{
  public static getDefaultProtocolGroup() {
    let data: any = {
      groupName: "",
      protocolIds: [],
      protocols: [],
      params: {
        duringWeek: {perWeek: 1, perDay: 1},
        iterations: 10,
        dayDuration: 7,
        waitSeconds: 10,
        maxIterations: 10,
        setsCount: 1,
        // iterationTime: 5
      }
    };
    return data;
  }

  public static getDefaultProtocolGroupEditor() {
    let data: any = this.getDefaultProtocolGroup();
    data.stages = ["wip"];
    return data;
  }

  public static getRehubEditorProtocolGroupFromJSON(protocolGroup: any) {
    let result = {...protocolGroup};
    result.protocolIds = result.protocolIds.filter((protocolId: string) => !protocolId.startsWith('CUSTOM'));
    result.protocols = result.protocols.filter((protocol: any) => !protocol.protocolId.startsWith('CUSTOM'));

    result.stages = ["wip"];
    result.fromJSON = true;

    delete result.groupId;

    return result;
  }

  public static getInfoRepsFromProtocolGroup(slug: string, protocolGroup: any, iterationTime: number) {
    let params;

    if (protocolGroup.protocols) {
      let protocol = protocolGroup.protocols.find((p: any) => p.protocolId == slug);
      if (protocol && protocol.params) {
        params = {...protocol.params};
      }
    }

    if (!params) params = {...protocolGroup.params ? protocolGroup.params : null};
    if (params && !params.iterationTime) params.iterationTime = iterationTime;
    return params;
  }
}