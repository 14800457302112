import { IRxjs } from "@rehub-shared/utils/rxjs.interface";
import { RehubProtocol, SensorPosition, SignalName, SignalShape } from "../utils/protocol-utils";

export abstract class ExerciseService{

  name: string = "";

  constructor(public rxjs: IRxjs){}

  // Funciones a subreescribir en la implementación
  public abstract init(): any;
  public abstract destroy(): void;
  public abstract send(command: string, value: any): void;
  public abstract receive(data: any): void;

  public equals(other: ExerciseService){
    return this.name == other.name;
  }

  public commandNewUser(idToken: string, stage: string){
    let value = {
      idToken: idToken,
      stage: stage
    }
    this.send("new_user", value);
  }

  public commandNewRecord(joint: string, protocol: string, secondarySignals: boolean){
    let value = {
      joint: joint,
      protocol: protocol || "",
      secondarySignals: secondarySignals
    }
    this.send("new_record", value);
  }

  public commandNewCameraTrackingSignalAssessment(record: any, mainSignal: SignalName, signalShape: SignalShape, exerciseId: string, referenceRecordSD: any, inverted: any, bilateral: boolean){
    let value: any = {
      bilateral: bilateral,
      mainSignal: mainSignal,
      inverted: inverted,
      hasStrength: false,
      frequency: 14,
      signalShape: signalShape,
      exerciseId: exerciseId,
      referenceFrequency: referenceRecordSD[mainSignal]['frequency'],
      useGyroQuaternion: false,
      referenceRecord: {
        minLine: referenceRecordSD[mainSignal]['minLine'],
        maxLine: referenceRecordSD[mainSignal]['maxLine'],
        averageCycle: referenceRecordSD[mainSignal]['averageCycle'],
      },
      signals: this.getSignalsFromRecord(record)
    }

    this.send("new_signal_assessment", value);
  }

  public commandNewSignalAssessment(record: any, mainSignal: SignalName, signalShape: SignalShape, exerciseId: string, referenceRecordSD: any, inverted: any, hasStrength: boolean, hasROM: boolean, active: boolean, bilateral: boolean){
    let value: any = {
      bilateral: bilateral,
      mainSignal: mainSignal,
      inverted: inverted,
      hasStrength: hasStrength,
      frequency: 25,
      signalShape: signalShape,
      exerciseId: exerciseId,
      referenceFrequency: referenceRecordSD[mainSignal]['frequency'],
      useGyroQuaternion: active,
      referenceRecord: {
        minLine: referenceRecordSD[mainSignal]['minLine'],
        maxLine: referenceRecordSD[mainSignal]['maxLine'],
        averageCycle: referenceRecordSD[mainSignal]['averageCycle'],
        GyroX: referenceRecordSD['GyroX'],
        GyroY: referenceRecordSD['GyroY'],
        GyroZ: referenceRecordSD['GyroZ'],
        Accel: referenceRecordSD['Accel']
      },
      signals: this.getSignalsFromRecord(record)
    }

    if(hasStrength && hasROM){
      value.strength = {
        minLine: referenceRecordSD["Strength"]['minLine'],
        maxLine: referenceRecordSD["Strength"]['maxLine'],
        averageCycle: referenceRecordSD["Strength"]['averageCycle']
      }
    }

    this.send("new_signal_assessment", value);
  }

  private getSignalsFromRecord(record: any){
    let mainSignal = Object.keys(record.rom)[0];
    let signals: any = record.rom;
    signals[mainSignal].tolerance = record.tolerance;
    // TODO: la tolerancia de fuerza no llega en el record a veces, hay que ir a buscarla al referenceRecord
    if(record.strength != undefined){
      signals["Strength"] = {
        min: record.strengthMin,
        max: record.strength,
        tolerance: record.toleranceStr
      }
    }

    return signals;
  }

  public commandNewSignalTracker(mainSignal: SignalName, signalShape: SignalShape, therapyId: string, minRange: number, maxRange: number, inverted: any, hasStrength: boolean, active: boolean, bilateral: boolean){
    let value = {
      bilateral: bilateral,
      mainSignal: mainSignal,
      inverted: inverted,
      hasStrength: hasStrength,
      frequency: 50,
      signalShape: signalShape,
      therapyId: therapyId,
      minRange: minRange,
      maxRange: maxRange,
      useGyroQuaternion: active
    }

    this.send("new_signal_tracker", value);
  }

  public commandNewCustomProtocol(){
    this.send("new_custom_protocol", {});
  }

  public commandSetSensor(mac: string){
    let value = {
      mac: mac,
      connect: true
    }
    this.send("set_sensor", value);
  }

  public commandSetCameraTracking(record: any, protocol: RehubProtocol, threshold: number){
    let params: any = {
      cam_plane: protocol.cam_plane,
      cam_mainMovement: protocol.cam_mainMovement,
      cam_lock_plane: protocol.cam_lock_plane,
      cam_secondaryMovements: protocol.cam_secondaryMovements,
      cam_offsets: protocol.cam_offsets,
      cam_model: protocol.cam_model,
      threshold: threshold,
      positions: protocol.positions,
      signals: this.getSignalsFromRecord(record)
    }

    if(record.bandColor) params.bandColor = record.bandColor;
    if(record.bandLength) params.bandLength = record.bandLength;

    if(record.params && record.params.bandColor) params.bandColor = record.params.bandColor;
    if(record.params && record.params.bandLength) params.bandLength = record.params.bandLength;

    this.send("set_camera_tracking", params);
  }

  public commandSetCameraTrackingData(data: any){
    this.send("set_camera_tracking_data", {keypoints: data});
  }

  public commandSetCameraTrackingModelConfig(config: any){
    this.send("set_camera_tracking_model_config", config);
  }

  public commandDisconnectSensor(){
    this.send("disconnect_sensor", {});
  }

  public commandStartStream(){
    this.send("start_stream", {});
  }

  public commandZero(mode: string = "ALL", reset: boolean = true){
    let value = {reset: reset};

    if (mode == "ALL") {
      this.send("set_zero", value);
      this.send("set_zero_gauge", {});
    } else if (mode == "ROM"){
      this.send("set_zero", value);
    } else if (mode == "STRENGTH"){
      this.send("set_zero_gauge", {});
    }
  }

  public commandStartStreamCalibration(){
    let value = {
      signals: ["Pitch", "Stationary"]
    }

    this.send("start_stream", value);
  }

  public commandStopStream(){
    this.send("stop_stream", {});
  }

  public commandCheckSensorPosition(sensorPosition: SensorPosition){
    let value = {
      sensorPosition: sensorPosition
    }

    this.send("check_sensor_position", value);
  }

  public commandInvertMainSignal(){
    this.send("invert_main_signal", {invert: true});
  }

  public commandRecalibrateGyroscope(){
    this.send("recalibrate_gyroscope", {});
  }

  public commandRecalibrateGauge(){
    this.send("recalibrate_gauge", {});
  }

  public commandStartRecord(){
    this.send("start_record", {});
  }

  public commandStopRecord(){
    this.send("stop_record", {});
  }

  public commandResetRecord(){
    this.send("reset_record", {});
  }

  public commandStartProcessRecord(){
    this.send("start_process_record", {});
  }

  public commandStopProcessRecord(){
    this.send("stop_process_record", {});
  }

  public commandUploadRecord(){
    this.send("upload_record", {});
  }

  public commandGetUploadRecord(){
    this.send("get_upload_record", {});
  }

  public commandGetScore(){
    this.send("get_score", {});
  }

  public commandInvertedSignals(signals: any){
    this.send("inverted_signals", signals);
  }

  public commandActionSetEnvironment(env: string){
    this.send("action_set_environment", {env: env});
  }

  public commandActionUpdate(data: any){
    this.send("action_update", data);
  }

  public commandActionReset(){
    this.send("action_reset", {});
  }

  public commandActionReady(){
    this.send("action_ready", {});
  }

  public commandPing(){
    this.send("ping", {});
  }
}