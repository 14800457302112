export enum FormName {
  EQ_5D_5L = "EQ-5D-5L",
  TSK_13 = "TSK-13",
  WOMAC = "WOMAC",
  QuickDASH = "QuickDASH",
  RDQ = "RDQ",
  MRC = "MRC",
  NDI = "NDI",
  WOSI = "WOSI",
  SPADI = "SPADI",
  SST = "SST",
  PSS = "PSS",
  OSS = "OSS",
  OKS = "OKS",
  OHS = "OHS",
  HAGOS = "HAGOS",
  HOOS = "HOOS",
  KOOS = "KOOS",
  FADI = "FADI",
  PRWE = "PRWE",
  QBPDS = "QBPDS",
  CAT = "CAT",
  PGIC = "PGIC",
  ODI = "ODI",
  TegnerScore = "TegnerScore",
  MNA = "MNA",
  GDS = "GDS",
  GRBAS = "GRBAS",
  VHI_10 = "VHI-10",
  VHI_30 = "VHI-30",
  SWAL_QOL = "SWAL-QOL",
  QoL_Dys = "QoL-Dys",
  EAT_10 = "EAT-10",
  HADS = "HADS",
  B_SAQ = "B-SAQ",
  ICIQ_SF = "ICIQ-SF",
  Wexner = "Wexner",
  DASH = "DASH",
  EVA = "EVA",
  Minnesota = "Minnesota",
  SF12 = "SF12",
  PCS = "PCS",
  TSK_11 = "TSK-11",
  Kiddy_KINDL = "Kiddy-KINDL",
  Kid_KINDL = "Kid-KINDL",
  Kiddo_KINDL = "Kiddo-KINDL",
  Kiddy_KINDL_parents = "Kiddy-KINDL-parents",
  Kid_Kiddo_KINDL_parents = "Kid-Kiddo-KINDL-parents",
  MPI_Pain = "MPI-Pain",
  MPI_Activities = "MPI-Activities",
  MPI_SO = "MPI_SO",
  DASS_21 = "DASS-21",
  Bournemouth_back = "Bournemouth-back",
  PFIQ_7 = "PFIQ-7",
  BORG_CR10 = "BORG-CR10",
  WDQ = "WDQ"
}
export class SharedFormService {

  private NUM_REQUIRED_SECTIONS_QUICKDASH: number = 3;
  private NUM_MAX_SECTIONS_QUICKDASH: number = 5;
  private NUM_MIN_ANSWERS_QUICKDASH: number = 10;
  private NUM_REQUIRED_SECTIONS_DASH: number = 6;
  private NUM_MAX_SECTIONS_DASH: number = 7;
  private NUM_MIN_ANSWERS_DASH: number = 30;

  answerTypes: any;
  formCategories: any;
  scoreOrder: any;

  forms: any = {};

  constructor() { }

  getAnswerTypes() {
    return this.answerTypes;
  }

  getForm(formId: string) {
    return this.forms[formId];
  }

  getAllForms() {
    return this.forms;
  }

  getFormTitle(formId: string, languageKey: string) {
    languageKey = (languageKey == "ca") || (languageKey =="esMX") ? "es" : languageKey;
    let form = this.getForm(formId);
    if (form){
      return form.title[languageKey];
    } else {
      return "-"
    }
  }

  getFAFormPeriodicities() {
    return [
      { value: "DAILY", text: "jointForm_periodicity_daily" },
      { value: "WEEKLY", text: "jointForm_periodicity_weekly" },
      { value: "MONTHLY", text: "jointForm_periodicity_monthly" },
      { value: "QUARTERLY", text: "jointForm_periodicity_quarterly" },
      { value: "BEGGINING", text: "jointForm_periodicity_beggining" },
      { value: "END", text: "jointForm_periodicity_end" },
      { value: "BEGGINING_END", text: "jointForm_periodicity_beggining_end" },
      { value: "BEGGINING_MIDDLE_END", text: "jointForm_periodicity_beggining_middle_end" }
    ];
  }

  getPeriodicitiesForForm(form: string){
    let formId = this.getFormId(form);
    if(formId == FormName.PGIC){
      let end = this.getEndPeriodicity();
      return [end];
    }else{
      return this.getFAFormPeriodicities();
    }
  }

  getEndPeriodicity(){
    return this.getFAFormPeriodicities().find((periodicity: any)=>periodicity.value == "END");
  }

  getNumberOfQuestions(formData: any, finalSlice: number = formData.sections.length) {
    let total = 0;
    formData.sections.slice(0, finalSlice).forEach((section: any) => {
      section.questions.forEach((question: any) => {
        total++;
      });
    });
    return total;
  }

  getNumberOfCheckQuestions(formData: any) {
    let total = 0;
    formData.sections.forEach((section: any) => {
      total++;
    });
    return total;
  }

  getAnswerOfType(type: any) {
    return this.answerTypes.find((answerType: any) => {
      return answerType.id == type;
    });
  }

  createAvailableFormOptions(joint: any, category: string) {

    let formsOfJoint = this.formCategories["joint_" + category].filter((f: any) => f.joints.includes(joint));
    let formsOfOtherJoints = this.formCategories["joint_" + category].filter((f: any) => !f.joints.includes(joint));

    return {
      general: this.formCategories["general_" + category].map((f: any) => f.name),
      joint: formsOfJoint.map((f: any) => f.name),
      other: formsOfOtherJoints.map((f: any) => f.name)
    };
  }

  getScoreOrder(formId: string) {
    return this.scoreOrder[formId];
  }

  formatDiagnosisForms(diagnosis: any){
    return diagnosis.forms.map((form: string)=>{
      let diagnosisPeriodicity = this.getPeriodicityFromDiagnosis(diagnosis);
      return this.formatDiagnosisForm(form, diagnosisPeriodicity);
    });
  }

  getFormId(form: string){
    let itemParts = form.split("_*_");
    return itemParts[0];
  }

  getFormPeriodicity(diagnosis: any, form: string){
    let itemParts = form.split("_*_");
    let periodicity = this.getPeriodicityFromDiagnosis(diagnosis);

    if(this.getFormId(form) == FormName.PGIC) periodicity = this.getEndPeriodicity();

    return itemParts.length == 2 ? itemParts[1] : periodicity;
  }

  getPeriodicityFromDiagnosis(diagnosis: any){
    return diagnosis.faFormPeriodicity || "DAILY";
  }

  formatDiagnosisForm(form: string, periodicity: string){
    if(form.includes(FormName.PGIC)) periodicity = "END";

    if(form.includes("_*_")) return form;
    else return `${form}_*_${periodicity}`;
  }

  setAnswer(answers: any, question: any, index: any, section: any) {
    //console.log(question, index, section);
    //Si hay maxResponses se eliminan todas las respuestas de la sección
    if (section.maxResponses) {
      section.questions.forEach((q: any) => {
        delete answers[q.id];
      });
    }

    if (index == null) {
      delete answers[question.id];
      return;
    }

    let value;
    let answerType = this.getAnswerOfType(question.answerType);

    if (answerType.type == "slider") {
      value = {
        v: parseFloat(index)
      };
    } else if (answerType.type == "text") {
      value = {
        v: index
      };
    } else if (answerType.type == "balls") {
      value = {
        v: answerType.answers[index].value,
        i: index
      };
    }

    if (value == null) delete answers[question.id];
    else answers[question.id] = value;
  }

  isValid(answers: any, formId: string, data: any) {
    let length;
    switch(formId) {
      case FormName.RDQ:
      //Se pueden dejar en blanco
      case FormName.SPADI:
      case FormName.PSS:
          // Las dos primeras secciones son obligatorias pero como tienen el slider y 0 es un valor posible no hace falta comprobar nada
          // La tercera sección es opcional y se pueden responder las preguntas que se quiera, por lo tanto siempre es válido
          return true;
      case FormName.WOSI:
      case FormName.EQ_5D_5L:
      case FormName.TSK_13:
      case FormName.WOMAC:
      case FormName.SST:
      case FormName.QBPDS:
      case FormName.CAT:
      case FormName.SWAL_QOL:
      case FormName.QoL_Dys:
      case FormName.EAT_10:
      case FormName.Wexner:
      case FormName.B_SAQ:
      case FormName.EVA:
      case FormName.Minnesota:
      case FormName.MPI_Pain:
      case FormName.MPI_SO:
      case FormName.MPI_Activities:
      case FormName.PCS:
      case FormName.GRBAS:
      case FormName.VHI_10:
      case FormName.VHI_30:
      case FormName.TSK_11:
      case FormName.DASS_21:
      case FormName.Bournemouth_back:
      case FormName.BORG_CR10:
      case FormName.WDQ:
        //Responder todas
        return this.allQuestionsAnswered(answers, data);
      case FormName.QuickDASH:
        return this.isValidDashes(answers, data, this.NUM_REQUIRED_SECTIONS_QUICKDASH, this.NUM_MAX_SECTIONS_QUICKDASH,
          this.NUM_MIN_ANSWERS_QUICKDASH);
      case FormName.DASH:
        return this.isValidDashes(answers, data, this.NUM_REQUIRED_SECTIONS_DASH, this.NUM_MAX_SECTIONS_DASH,
          this.NUM_MIN_ANSWERS_DASH);
      case FormName.MRC:
      case FormName.PGIC:
      case FormName.ODI:
        //Mirar que se haya respondido cualquier de la primera seccion
        let section1Answered = data.sections[0].questions.some((question: any) => {
          return answers[question.id] != null;
        });
        return section1Answered;
      case FormName.NDI:
        //Mirar que como mínimo haya 9 respuestas
        length = Object.keys(answers).length;
        return length >= 9;
      case FormName.OSS:
      case FormName.OKS:
      case FormName.OHS:
      case FormName.GDS:
        // Contestar como mínimo 10 preguntas de todo el formulario
        length = Object.keys(answers).length;
        return length >= 10;
      case FormName.HAGOS:
        return this.isValidHagos(answers, data);
      case FormName.HOOS:
        return this.isValidHoos(answers, data);
      case FormName.KOOS:
        return this.isValidKoos(answers, data);
      case FormName.FADI:
        return this.isAnsweredMinForm(answers, data, 2);
      case FormName.PRWE:
        return this.isAnsweredMinForm(answers, data, 1);
      case FormName.TegnerScore:
      case FormName.MNA:
      case FormName.HADS:
      case FormName.PFIQ_7:
            return this.allQuestionsCheckAnswered(answers, data);
      case FormName.ICIQ_SF:
        return this.allQuestionsCheckAnsweredICIQSF(answers, data);
      case FormName.Kiddy_KINDL:
      case FormName.Kid_KINDL:
      case FormName.Kiddo_KINDL:
      case FormName.Kiddy_KINDL_parents:
      case FormName.Kid_Kiddo_KINDL_parents:
        return this.isLastAnsweredOptional(answers, data);
      case FormName.SF12:
        return this.isValidSF12(answers, data);
      default:
        return false;
    }
  }

  /**
   * Para el formulario DASS-21, calcula el nombre a mostrar según la puntuación obtenida en un modificador
   * @param score Puntuación actual del modificador
   * @param mod Nombre del modificador
   * @returns El identificador del nombre a mostrar
   */
  getDASS21ScoreModName(score: number, mod: string): string | undefined{
    let scores: any = {
      normal: [[0, 9], [0, 7], [0, 14]],
      mild: [[10, 13], [8, 9], [15, 18]],
      moderate: [[14, 20], [10, 14], [19, 25]],
      severe: [[21, 27], [15, 19], [26, 33]],
      extremely_severe: [[28], [20], [34]]
    }

    let keys = Object.keys(scores);
    let mods = ["mod1", "mod2", "mod3"];
    let index = mods.indexOf(mod);

    if(index == -1) return undefined;

    return keys.find((key: string)=>{
      let item = scores[key][index];
      let min = item[0];
      let max = item.length > 1 ? item[1] : null;

      return max ? score >= min && score <= max: score >= min;
    });
  }

  private allQuestionsAnswered(answers: any, data: any) {
    let totalQuestions = this.getNumberOfQuestions(data);
    let totalAnswered = this.getQuestionsAnswered(answers, data);
    return totalQuestions == totalAnswered;
  }

  private isLastAnsweredOptional(answers: any, data: any) {
    let totalQuestions = this.getNumberOfQuestions(data, data.sections.length - 1);
    let totalAnswered = this.getQuestionsAnswered(answers, data, data.sections.length - 1);
    return totalQuestions == totalAnswered;
  }


  private allQuestionsCheckAnswered(answers: any, data: any) {
    let totalQuestions = this.getNumberOfCheckQuestions(data);
    let totalAnswered = this.getQuestionsAnswered(answers, data);
    console.log("totalQuestions", totalQuestions, "totalAnswered", totalAnswered);
    return totalQuestions == totalAnswered;
  }

  private allQuestionsCheckAnsweredICIQSF(answers: any, data: any) {
    let totalQuestions = this.getNumberOfCheckQuestions(data);
    let totalAnswered = this.getQuestionsAnsweredICIQSF(answers, data);
    return totalQuestions == totalAnswered;
  }

  private getQuestionsAnswered(answers: any, data: any, finalSlice: number = data.sections.length) {
    let count = 0;
    data.sections.slice(0, finalSlice).forEach((section: any) => {
      section.questions.forEach((question: any) => {
        if (answers[question.id] != null) count++;
      });
    });
    return count;
  }


  private getQuestionsAnsweredICIQSF(answers: any, data: any) {
    let count = 0;
    let keys = Object.keys(answers);
    data.sections.forEach((section: any) => {
      let ids = section.questions.map((question: any) => question.id);
      if (keys.some((key: string) => ids.includes(key))) count++;
    });
    return count;
  }

  private isAnsweredMinForm(answers: any, data: any, numAnsweredMin: number) {
    let valid = true;
    //Solo se pueden dejar maximo n sin responder en cada sección
    let i = 0;
    while (i < data.sections.length && valid) {
      let noAnswered = 0;
      data.sections[i].questions.forEach((question: any) => {
        if (answers[question.id] == null) noAnswered++;
      });
      valid = noAnswered <= numAnsweredMin;
      i++;
    }

    return valid;
  }

  private isValidDashes(answers: any, data: any, numRequiredSection: number, maxSections: number, minAnswers: number) {
    // Parte obligatoria como mínimo responder 10 de 11 (sección A, B y C)
    let answered = 0;
    for (let i = 0; i < numRequiredSection; i++) {
      data.sections[i].questions.forEach((question: any) => {
        if (answers[question.id] != null) answered++;
      });
    }
    if (answered < minAnswers) return false;
    // Si se responde una pregunta de otra sección se tiene que completar la sección entera
    let valid = true;

    for (let i = numRequiredSection; i < maxSections; i++) {
      let total = 0;
      let answered = 0;
      data.sections[i].questions.forEach((question: any) => {
        total++;
        if (answers[question.id] != null) answered++;
      });
      if (answered > 0 && answered < total) valid = false;
    }
    return valid;
  }

  private isValidHagos(answers: any, data: any) {
    //En todas las secciones se pueden dejar sin responder 2 menos en la seccion 6 que solo se puede dejar 1
    let valid = true;

    //Grupo 1: Seccion 1 y 2 solo se pueden dejar maximo 2 sin responder
    let total = 0;
    let answered = 0;
    for (let i = 0; i < 2; i++) {
      data.sections[i].questions.forEach((question: any) => {
        total++;
        if (answers[question.id] != null) answered++;
      });
    }

    if (answered < total - 2) return false;

    //Grupo 2: Seccion 3 y 4
    total = 0;
    answered = 0;
    for (let i = 2; i < 4; i++) {
      data.sections[i].questions.forEach((question: any) => {
        total++;
        if (answers[question.id] != null) answered++;
      });
    }

    if (answered < total - 2) return false;

    for (let i = 4; i < 8; i++) {
      let total = 0;
      let answered = 0;
      data.sections[i].questions.forEach((question: any) => {
        total++;
        if (answers[question.id] != null) answered++;
      });

      if (i == 6 && answered < total - 1) valid = false;
      else if (answered < total - 2) valid = false;
    }

    return valid;
  }

  private isValidHoos(answers: any, data: any) {
    let answered = 0;
    for (let i = 0; i < 2; i++) {
      data.sections[i].questions.forEach((question: any) => {
        if (answers[question.id] != null) answered++;
      });
    }

    if (answered < 3) return false;

    let valid = true;
    for (let i = 2; i < 6; i++) {
      let total = 0;
      let answered = 0;
      data.sections[i].questions.forEach((question: any) => {
        total++;
        if (answers[question.id] != null) answered++;
      });
      if (answered < (total / 2)) valid = false;
    }
    return valid;
  }

  private isValidKoos(answers: any, data: any) {
    let answered = 0;
    for (let i = 0; i < 2; i++) {
      data.sections[i].questions.forEach((question: any) => {
        if (answers[question.id] != null) answered++;
      });
    }

    if (answered < 3) return false;

    answered = 0;
    for (let i = 2; i < 4; i++) {
      data.sections[i].questions.forEach((question: any) => {
        if (answers[question.id] != null) answered++;
      });
    }

    if (answered < 5) return false;

    let valid = true;
    for (let i = 4; i < 7; i++) {
      let total = 0;
      let answered = 0;
      data.sections[i].questions.forEach((question: any) => {
        total++;
        if (answers[question.id] != null) answered++;
      });

      if (answered < (total / 2)) valid = false;
    }
    return valid;
  }

  private isValidSF12(answers: any, data: any) {
    // Hay 12 preguntas, las secciones 1, 5 y 7 tienen una sola pregunta
    let length = Object.keys(answers).length;
    return length == 12;
  }


}