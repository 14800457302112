import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NotificationService, EventBusService, ModalService, ToastService, LocalSettingsService, MeasurementProgramService, WebViewService } from '@app/@core/service';

import { VoiceControlService } from '@app/@core/service/voice-control.service';
import { IVoiceControlEvent } from '@rehub-shared/service/voice-control.service';
import { AudioManager } from "@rehub-shared/utils/audio-manager";
import { DeployInfoService } from "@app/@core/service/deploy-info.service";

import { ModalComponent } from '@app/@shared/modal/modal.component';
import { ConfirmModalComponent } from '@app/@shared/modal/confirm-modal/confirm-modal.component';
import { CoachComponent } from '@app/@shared';
import { Utils } from '@app/utils';

import { AuthenticationService, CredentialsService } from '@core/auth';
import { environment } from '@env/environment';

import { SharedUtils } from '@rehub-shared/utils/utils';

import { WebsocketExerciseService } from '@rehub-shared/measurement-program/websocket-exercise.service';
import { I18nService } from '@app/i18n';
import { FeatureFlagsService } from '@app/@core/service/feature-flags.service';
import { NotificationManagerService } from '@app/@core/service/notification-manager.service';
import { CallingModalComponent } from '@app/@shared/modal/calling-modal/calling-modal.component';
import { RCSenderService } from '@app/@core/service/rc/sender.service';
import { ControllerType } from '@rehub-shared/remote-controller/rc-common';
import { ChromecastManager } from '@rehub-shared/remote-controller/chromecast/chromecast-manager';
import { ChromecastCallbacks } from '@rehub-shared/remote-controller/chromecast/chromecast-callback.interface';
import { CoachService } from '@app/@core/service/coach.service';
import { API, PatientAPI, SensorAPI } from '@rehub-shared/api';
import { RxjsImpl } from '@app/@core/service/shared-implementations/rxjs.impl';
import { Permission } from '@rehub-shared/utils/permissions';

const SKIP_TIMERS = "skip_timers_"
@Component({
  selector: 'rehub-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy, IVoiceControlEvent, ChromecastCallbacks {

  private COMPONENT_NAME: string = "HEADER_COMPONENT";

  Object = Object;
  Utils = Utils;

  menuHidden = true;

  username: string;
  fullName: string;
  profileSettings : any;

  isCoachDisabled: boolean = false;
  isMicroDisabled: boolean = false;
  voiceCredentials: boolean = false;

  showHelp: boolean = false;

  menuItems: any[];

  showHelpExcludedRoutes: string[] = ["/help"];
  showCoachExcludedRoutes: string[] = ["/help"]; //, "/evolution", "/chat", "/calendar", "/forms"];

  sub: string;
  isCustomLogo: boolean = false;
  customLogo: string;
  isSelfServices : boolean = false;

  dailyFormFinished: boolean = false;
  hasNewMessage: boolean = false;

  measurementProgramStatusSubscription: any;
  currentUserUpdatedSubscription: any;
  dailyFormFinishedSubscription: any;
  notificationsReceivedSubscription: any;
  newChatMessageSubscription: any;
  remoteControllerSubscription: any;
  voiceControlSubscription: any;
  voiceRecognitionEnabled: boolean = false;

  chromecastManager: ChromecastManager;
  loadingWS: boolean = false;
  rcInterval: any;
  loadingChromecast: boolean = false;
  showChromecast: boolean = true;
  isWellwo: boolean = false;
  isChatDisabled: boolean = false;

  audioStream: any;

  audioDevices: MediaStreamTrack[] = [];
  selectedAudioDevice: any = 0;
  isMobile: boolean = false;
  isAssegur: boolean = false;
  isCoachHidden: boolean = false;



  constructor(
    public featureFlagsService: FeatureFlagsService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private credentialsService: CredentialsService,
    private eventBus: EventBusService,
    private notificationService: NotificationService,
    private modalService: ModalService,
    private i18nService: I18nService,
    private notificationManagerService: NotificationManagerService,
    private senderService: RCSenderService,
    private toastService: ToastService,
    private zone: NgZone,
    private coachService: CoachService,
    private localSettingsService: LocalSettingsService,
    public measurementProgramService: MeasurementProgramService,
    private voiceControlService: VoiceControlService,
    private deployInfoService: DeployInfoService,
    private webview: WebViewService
  ) {}

  onKeyWordDetected(): void {
  }

  onCommandProcessing(): void {
  }

  onCommandProcessed(command: string): void {
    this.coachService.say({key: 'coach_voice_confirm_message'})
    console.log("onCommandProcessed", command)
    switch(command) {
      case "/goto_ejercicios":
        this.router.navigate(['/exercises']);
        break;
      case "/goto_calendario":
        this.router.navigate(['/calendar']);
        break;
      case "/goto_calendario":
        this.router.navigate(['/calendar']);
        break;
      case "/goto_chat":
        this.router.navigate(['/chat']);
        break;
      case "/goto_help":
        this.router.navigate(['/help/videos']);
        break;
      case "/goto_progress":
        this.router.navigate(['/evolution']);
        break;
      case "/goto_questionnaires":
        this.router.navigate(['/forms']);
        break;
      case "/start_exercise":
        this.router.navigate(['/exercises']);
    }
  }

  onCommandNotProcessed(coach_message: string, component_name: string): void {
    if(component_name === this.COMPONENT_NAME) {
      this.coachService.say({key: coach_message})
    }
  }

  ngOnDestroy() {
    if (this.measurementProgramStatusSubscription) this.measurementProgramStatusSubscription.unsubscribe();
    if (this.currentUserUpdatedSubscription) this.currentUserUpdatedSubscription.unsubscribe();
    if (this.dailyFormFinishedSubscription) this.dailyFormFinishedSubscription.unsubscribe();
    if (this.notificationsReceivedSubscription) this.notificationsReceivedSubscription.unsubscribe();
    if (this.newChatMessageSubscription) this.newChatMessageSubscription.unsubscribe();
    if (this.remoteControllerSubscription) this.remoteControllerSubscription.unsubscribe();
    if (this.voiceControlSubscription) this.voiceControlSubscription.unsubscribe();
    if (this.rcInterval) clearInterval(this.rcInterval);
    if (this.chromecastManager) this.chromecastManager = null;

    this.voiceControlService.removeComponent(this.COMPONENT_NAME);
  }

  ngOnInit() {
    this.isSelfServices= this.isSelfService()
    this.isAssegur = this.credentialsService.isAssegur();
    this.isMobile = Utils.isMobile();
    this.sub = this.credentialsService.getCredentials().sub;
    this.isWellwo = this.isProviderWellwo();
    this.isChatDisabled = this.chatDisabled();
    this.isCoachHidden = this.credentialsService.checkPermission(Permission.Coach_Patient_D);
    //TODO mirar como habilitar la posibilidad de desactivar timers
    // if(localStorage.getItem(SKIP_TIMERS + this.sub)) ButtonProgressBar.setSkipTimers(true);
    this.webview.postMessage('patientId',this.sub);
    this.menuItems = this.getMenuItems();
    if (this.credentialsService.getCredentials().center) {
      this.customLogo = SharedUtils.getLogoUrl(this.credentialsService.getCredentials().center.centerLogoOutputKey, environment.output_s3_url);
      this.isCustomLogo = this.customLogo && this.customLogo != "";
    }

    this.reload();

    this.initSubscribeEventBus();

    let settings: any = this.localSettingsService.get();
    this.isCoachDisabled = settings.coachDisabled == true;
    this.isMicroDisabled = settings.microDisabled == true;

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes('chat')) this.hasNewMessage = false;
        this.showHelp = this.getURLDoesNotMatch(this.router.url, this.showHelpExcludedRoutes);
        this.checkShowCoach();
      }
    });

    this.showHelp = this.getURLDoesNotMatch(this.router.url, this.showHelpExcludedRoutes);

    this.coachService.addToDOM(CoachComponent);

    this.checkShowCoach();
    this.notificationManagerService.checkServer();
    this.initMeasurementProgramService();

    if (this.credentialsService.getCredentials().chromecast != 'receiver') {
      this.checkPatientValidToInitChrome();
    }
    this.handleVoiceControl();

  }

  handleVoiceControl() {
    this.voiceCredentials = this.credentialsService.checkPermission(Permission.VoiceRecognition_Patient_U)
    if(this.voiceCredentials){
      this.eventBus.publish("MICRO_ENABLE", this.isMicroDisabled ? "disable" : "enable");
      !this.isMicroDisabled && this.initVoiceControl();
    }else{
      this.eventBus.publish("MICRO_ENABLE", "disable");
    }
  }

  initVoiceControl() {
    this.voiceControlService.init(
      this.sub,
      environment.professional_url,
      this.deployInfoService.getURLCacheParam()
    );

    this.addComponentToVoiceControl();

  }

  addComponentToVoiceControl() {
    this.voiceControlService.addComponent(
      this.COMPONENT_NAME,
      {
        "/goto_ejercicios": "Ver ejercicios.",
        "/goto_calendario": "Ir al calendario.",
        "/goto_chat": "Ver el chat.",
        "/goto_help": "Ir a la sección de ayuda.",
        "/goto_progress": "Ir a mostrar el progreso.",
        "/goto_questionnaires": "Ir a la sección de questionarios.",
        }
      ,
      {
        "¿Que tengo que hacer hoy?": "/goto_ejercicios",
        "¿Que ejercicios tengo?": "/goto_ejercicios",
        "¿Que me toca mañana?": "/goto_calendario",
        "¿Que ejercicios tengo los próximos días?": "/goto_calendario",
        "Tengo que hablar con mi fisio": "/goto_chat",
        "Llévame al chat": "/goto_chat",
        "No se que hacer": "/goto_help",
        "¿Como funciona?": "/goto_help",
        "¿Que tal va mi terapia?": "/goto_progress",
        "¿Estoy mejorando?": "/goto_progress",
        "Muestra mi progreso": "/goto_progress",
        "¿Tengo que completar algún questionario?": "/goto_questionnaires",
        "¿Tengo que hacer formularios?": "/goto_questionnaires",
        "¿Hay formularios pendientes?": "/goto_questionnaires",
        "Llevame a los formularios": "/goto_questionnaires",
        "Llevame a los questionarios": "/goto_questionnaires"
        }
      ,
      this
    );
  }

  showCast() {
    let castElements = Array.from(document.getElementsByTagName("google-cast-launcher") as HTMLCollectionOf<HTMLElement>);
    let show = false;
    castElements.forEach((element: HTMLElement) => {
      if (element.style.display == 'inline') show = true;
    });
    return show;
  }

  subscribeToRealtimeStatus() {
    this.measurementProgramStatusSubscription = this.measurementProgramService.onStatusChange().subscribe((status: any) => {
      if (!status) return;
      console.log("Realtime Status:", status);
      if (this.dailyFormFinished) this.showRealtimeStatusModals(status);
    });
  }

  showRealtimeStatusModals(status: string) {
    this.dailyFormFinished = false;
    if (status == "PENDING_INSTALL" && !this.measurementProgramService.isSkipRealtimeInstallEnabled()) {
      this.showInstallModal();
    } else if(status == "PENDING_UPDATE") {
      this.showUpdateModal();
    } else if(status == "DISCONNECTED" && !this.measurementProgramService.isSkipRealtimeInstallEnabled()) {
      this.showDisconnectedModal();
    }
  }

  toggleMenu() {
    this.menuHidden = !this.menuHidden;
  }

  hideMenu() {
    this.menuHidden = true;
  }

  logout() {
    let data = {title: "login_logout_confirm"};
    this.modalService.show(ModalComponent, ConfirmModalComponent, data, (result: any) => {
      this.modalService.close(false, false);
      if (result) {
        this.coachService.removeFromDOM();
        this.authenticationService.logout();
      }
    });
  }

  toggleFullScreen() {
    if(this.isInFullScreen()) Utils.closeFullscreen();
    else Utils.openFullscreen();
  }

  isInFullScreen() {
    return Utils.isInFullScreen();
  }

  onAvatarClick() {
    if(this.featureFlagsService.showProfilePatient && !this.isSelfServices) this.router.navigate(['/profile']);
  }

  toggleCoach() {
    this.isCoachDisabled = !this.isCoachDisabled;
    if (this.isCoachDisabled) this.eventBus.publish("COACH_DISABLE");
    else this.eventBus.publish("COACH_ENABLE");
  }

  toggleMicro() {
    this.isMicroDisabled = !this.isMicroDisabled;
    if ( this.isMicroDisabled ){
      this.eventBus.publish("MICRO_ENABLE", "disable");
    } else {
      this.eventBus.publish("MICRO_ENABLE", "enable");
      this.initVoiceControl()
    }
  }

  startRecordAudio() {
    this.addComponentToVoiceControl();
  }

  stopRecordAudio() {
    console.log("stopRecordAudio");
    this.voiceControlService.removeComponent(this.COMPONENT_NAME);
  }

  getRealtimeStatusColor() {
    let enabled = this.measurementProgramService.isEnabled();
    let status = this.measurementProgramService.getStatus();
    if (enabled && status == "CONNECTED") return "connected";
    else if (enabled && status == "CONNECTING" || status == "INSTALLING" || status == "UPDATING") return "loading";
    else return "disconnected";
  }

  redirectToForm() {
    window.open(SharedUtils.getSupportUrl({
      client: environment.client_login,
      patient: this.credentialsService.getInfo(),
      supportUrl: environment.login_url + "/contact-form",
      lang: this.i18nService.getLanguageCode(),
      backToLogin: false
    }));
  }

  getURLDoesNotMatch(url: string, routes: string[]) {
    // Si no coincide ninguna ruta se devuelve true
    return !routes.some((excludedRoute: string) => {
      return url.includes(excludedRoute);
    });
  }

  checkShowCoach() {
    let showCoach = this.getURLDoesNotMatch(this.router.url, this.showCoachExcludedRoutes);
    let coach: HTMLElement = this.coachService.getDOMReference().location.nativeElement;

    if (showCoach) {
      coach.classList.add("d-lg-block");
      // coach.classList.add("fade-in");
      // coach.classList.remove("fade");
    } else {
      coach.classList.remove("d-lg-block");
      // coach.classList.add("fade");
      // coach.classList.remove("fade-in");
    }
  }

  getMenuItems() {
    let menuItems = [
      {key: "navigation_help",  title: "help_header", icon: "helper", route: !this.isSelfServices ? '/help/videos' : '/help/about_selfservice', options: {exact: true}, show: true},
      {key: "navigation_profile", title: "navigation_profile", icon: "patient", route: "/profile", options: { exact: true, onlyMobile: true, hidden: !this.featureFlagsService.showProfilePatient || this.isSelfServices || this.isAssegur }, show: true },
      {key: "navigation_exercises", title: "navigation_exercises", icon: "barbell", route: "/", options: {exact: true}, show: true, onlyDesktop: true},
      {key: "navigation_calendar", title: "navigation_calendar", icon: "calendar_1", route: "/calendar", options: {exact: false}, show: !this.isWellwo, onlyDesktop: true},
      {key: "navigation_chat", title: "navigation_chat", icon: "message_1", route: "/chat", options: {exact: false, hidden: (!this.featureFlagsService.enableChatPatient || this.isChatDisabled)}, show: !this.isWellwo, onlyDesktop: true},
      {key: "navigation_evolution", title: "navigation_evolution", icon: "histogram", route: "/evolution", options: {exact: false}, show: true, onlyDesktop: true},
      {key: "navigation_forms", title: "navigation_forms", icon: "checklist", route: "/forms", options: {exact: false, hidden: true}, show: true},
    ];
    return menuItems
  }

  initRC(type: string) {
    this.webview.postMessage("openChromecastDialog", true);
    let controllerType: ControllerType = ControllerType.parse(type);
    this.loadingChromecast = true;
    this.senderService.createController(controllerType);
    let sender = this.senderService.getController();
    let intialised = sender.init(() => {
      clearInterval(this.rcInterval);
      this.zone.run(() => {
        this.loadingChromecast = false;
        this.router.navigate(['/remote-controller']);
      });
    });
    intialised.then(() => {
      this.rcInterval = setInterval(() => {
        sender.send(this.credentialsService.getCredentials());
      }, 3000);
    }, (error: any) => {
      this.loadingChromecast = false;
      console.error(error);
      this.toastService.show(error.code, "danger", true);
    });
  }

  isTestEnvironment() {
    return SharedUtils.isStage(environment.stage, ["test"]);
  }

  isTestEnvironmentOrDemo() {
    return SharedUtils.isStage(environment.stage, ["test", "demo3"])
  }

  sessionStarted(): void {
    this.initRC('Chromecast');
  }

  sessionStarting(): void {
    this.loadingChromecast = true;
  }

  sessionResumed(): void {
    if (this.senderService.getController()) this.senderService.getController().close();
    this.toastService.show(this.i18nService.translate("chromecast_error_session"), "danger", true);
  }

  sessionNoSession(): void {

  }

  sessionStartFailed(): void {
    this.loadingChromecast = false;
    this.toastService.show(this.i18nService.translate("chromecast_error_start_failed"), "danger", true);
  }

  sessionSessionEnded(): void {
    if (this.senderService.getController()) this.senderService.getController().close();
    window.location.href = environment.url;
  }


  onHelpPressed(){
    if (this.isSelfServices) this.router.navigate(['help/about_selfservice']);
    else  this.router.navigate(['/help/videos']);
  }

  isSelfService() {
    return this.credentialsService.isSelfService();
  }

  private reload() {
    let credentials = this.credentialsService.getCredentials();

    this.notificationsReceivedSubscription = this.eventBus.subscribe("NOTIFICATIONS_RECEIVED",(data: any) => {
      let pendingForms = NotificationService.hasFormResponseNotification(data.message);
      this.menuItems.find((menuItem: any)=>menuItem.key == "navigation_forms").options.hidden = !pendingForms;
    });

    this.notificationService.checkNotifications();

    let patient = credentials.patient.patient;
    this.profileSettings = {
      image: SharedUtils.getAvatarUrl(patient, environment.output_s3_url),
      name: patient.name,
      alias: patient.alias,
      login: credentials.patient.login
    };

    this.voiceRecognitionEnabled = credentials.parentPermissions.VoiceRecognition_Patient_U ? true : false;

    this.eventBus.publish("VOICE_SERVICE_ENABLE", this.voiceRecognitionEnabled);

  }

  private initSubscribeEventBus() {
    this.currentUserUpdatedSubscription = this.eventBus.subscribe("CURRENT_USER_UPDATED", (data: any) => {
      this.reload();
    });

    this.dailyFormFinishedSubscription = this.eventBus.subscribe("DAILY_FORM_FINISHED", (data: any) => {
      this.dailyFormFinished = true;
      let status = this.measurementProgramService.getStatus();
      if (status != null && status != "CONNECTING") this.showRealtimeStatusModals(status);
    });

    this.newChatMessageSubscription = this.eventBus.subscribe("WS_NEW_CHAT_MESSAGE", (data: any) => {
      this.hasNewMessage = true;
      if (data.message.message.callStatus == 'START_CALL') {

        let callingData = {
          sortKey: data.message.message.sortKey,
          userId: this.sub,
          groupId: data.message.message.groupId,
          professional: data.message.message.ownerInfo
        };

        this.modalService.show(ModalComponent, CallingModalComponent, callingData);
      }
    });

    this.remoteControllerSubscription = this.eventBus.subscribe("RC_RECEIVER", (data: any) => {

    });
  }

  private initMeasurementProgramService() {
    let params = {
      sub: this.sub,
      skipSensorAndVersionCheck: false,
      stage: environment.stage,
      realtimeInfo: this.credentialsService.getCredentials().realtimeInfo
    };

    let exerciseService;

    if (SharedUtils.isWindows()) exerciseService = new WebsocketExerciseService(new RxjsImpl());

    this.measurementProgramService.init(exerciseService, params).then((isEnabled: boolean) => {
      if (isEnabled) this.subscribeToRealtimeStatus();
    });
  }

  //TODO! refactor?
  private checkPatientValidToInitChrome() {
    let patientId = this.credentialsService.getCredentials().sub;

    let batch = {
      sensorKits: SensorAPI.getSensorKitsByOwner(patientId, false),
      patientInfo: PatientAPI.getPatientInfo(patientId),
      exercises: PatientAPI.getPatientExerciseByDate(patientId, "TODAY")
    };

    API.allFromObject(batch).then((result: any) => {

      //TODO creo que hay mejor manera de detectar todo esto
      let isProtocolOnlyVideo = Object.values(result.exercises.protocolData).some((protocol: any) => !protocol.sensorEnabled && !protocol.cameraEnabled);
      if (isProtocolOnlyVideo || result.patientInfo.noSensor) this.showChromecast = true;
      else {
        let isProtocolSensor = Object.values(result.exercises.protocolData).some((protocol: any) => protocol.sensorEnabled && !protocol.cameraEnabled);
        let isProtocolCamera = Object.values(result.exercises.protocolData).some((protocol: any) => !protocol.sensorEnabled && protocol.cameraEnabled);
        let isProtocolSensorCamera = Object.values(result.exercises.protocolData).some((protocol: any) => protocol.sensorEnabled && protocol.cameraEnabled);

        this.showChromecast = ((!result.sensorKits || result.sensorKits.length == 0) && isProtocolSensor)
          || (!result.patientInfo.useCamera && isProtocolCamera)
          || (!result.sensorKits || result.sensorKits.length == 0) && !result.patientInfo.useCamera && isProtocolSensorCamera;
      }

      //show always chromecast in demo3 and test
      if(this.isTestEnvironmentOrDemo()) this.showChromecast = true;

      if (this.showChromecast) this.chromecastManager = new ChromecastManager(environment.chromecastAppId, this);
    });
  }

  private showUpdateModal(){
    this.measurementProgramService.updateRealtime();
    this.measurementProgramService.showUpdate({});
  }

  private showDisconnectedModal() {
    this.measurementProgramService.showDisconnected({});
  }

  private showInstallModal() {
    this.measurementProgramService.showInstall({});
  }

  private isProviderWellwo() {
    return this.credentialsService.checkPermission(Permission.SS_config_wellwo)
  }

  private chatDisabled() {
    return this.credentialsService.checkPermission(Permission.Chat_Patient_D)
  }


}
